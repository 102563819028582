import gql from 'graphql-tag';

export const getInvoiceDetail = gql`
  query getInvoiceDetail($invoiceId: Int!, $tenantId: Int!) {
    getInvoiceDetail(invoiceId: $invoiceId, tenantId: $tenantId) {
      id
      currency_symbol
      created_at
      invoice_number
      customer_name
      billing_name
      billing_address
      sub_total
      sub_total_tax
      delivery_fee
      delivery_fee_is_tax_inclusive
      total_tax
      discount
      grand_total
      supplier_name
      actual_delivery_date
      display_grand_total
      amount
      outstanding_amount
      customer_due_date
      invoice_items {
        product_name
        total_qty
        sale_unit_price
        total_price
        tax
        is_order_by_weight
        total_weight
        price_is_tax_inclusive
        order_items {
          is_delivery_fee
          is_discount_item
          sku_snapshot
          order {
            is_pickup
          }
          sku {
            alias
          }
        }
      }
      credit_note {
        id
        credit_note_number
        amount
        created_at
        credit_note_date
      }
      debitNotes {
        id
        debit_note_number
        amount
        outstanding_amount
        debitNoteItems {
          id
          order_item_id
          quantity
          unit_price
          total_price
          tax
          tax_rate
          orderItem {
            product_name
            is_delivery_fee
            is_discount_item
          }
        }
      }
    }
  }
`;

export const getDebitNoteDetail = gql`
  query getDebitNoteDetail($debitNoteId: Int!, $tenantId: Int!) {
    getDebitNoteDetail(debitNoteId: $debitNoteId, tenantId: $tenantId) {
      id
      debit_note_number
      amount
      outstanding_amount
      sub_total
      sub_total_tax
      delivery_fee
      delivery_fee_is_tax_inclusive
      total_tax
      grand_total
      debitNoteItems {
        id
        order_item_id
        quantity
        unit_price
        total_price
        tax
        tax_rate
        price_is_tax_inclusive
        orderItem {
          product_name
          is_delivery_fee
          is_discount_item
        }
      }
    }
  }
`;

export const downloadInvoice = gql`
  mutation downloadInvoice($orderId: Int!, $debitNoteId: Int) {
    downloadInvoice(orderId: $orderId, debitNoteId: $debitNoteId)
  }
`;

export const downloadInvoiceByInvoiceId = gql`
  mutation downloadInvoiceByInvoiceId($invoiceId: Int!, $debitNoteId: Int) {
    downloadInvoiceByInvoiceId(invoiceId: $invoiceId, debitNoteId: $debitNoteId)
  }
`;

export const downloadInvoiceCNPdf = gql`
  mutation downloadInvoiceCNPdf($orderId: Int!, $creditNoteIds: [Int]) {
    downloadInvoiceCNPdf(orderId: $orderId, creditNoteIds: $creditNoteIds)
  }
`;

export const downloadCreditNotePdf = gql`
  mutation downloadCreditNotePdf($creditNoteId: Int!) {
    downloadCreditNotePdf(creditNoteId: $creditNoteId)
  }
`;
