import gql from 'graphql-tag';

export const getOrderDetails = gql`
  query getOrderDetails($orderId: Int) {
    getOrderDetails(orderId: $orderId) {
      id
      tenant_id
      order_status
      delivery_date
      delivery_time
      delivery_address
      delivery_name
      delivery_instruction
      billing_address
      billing_name
      payment_method
      po_number
      total_price
      total_tax
      description
      is_booking
      version
      is_prepaid
      is_pickup
      is_waive_delivery_fee
      card_info {
        exp_month
        exp_year
        last4
        brand
      }
      orderStatus {
        id
        name
        description
      }
      last_order_log_info {
        status
        created_at
        reason
        changed_by
        changed_by_user_type
        images
        tracking_link
        app_name
        reason_type
      }
      customer_credit_term {
        displayName
        termPeriod
        gracePeriod
        customerCreditTermId
        tenantCreditTermId
      }
      orderItems {
        id
        invoice_id
        order_id
        sku_id
        user_id
        customer_buyer_id
        order_item_status_id
        order_item_status
        customer_seller_id
        amount_qty
        total_qty
        origin_unit_price
        sale_unit_price
        total_price
        price_is_tax_inclusive
        tax
        tax_rate
        catch_weight
        is_catch_weight
        total_weight
        weight
        active
        cancelled
        product
        sku_name
        product_name
        is_sample
        oom
        image
        tenant_name
        customer_name
        account_number
        alias_name
        minimum_order
        tenant_id
        user_name
        mobile
        stripe_card_id
        delivery_status
        is_order_by_weight
        billing_address
        delivery_address
        dispute_status
        dispute_reason
        dispute_remedy
        increment_qty
        description
        halal
        is_delivery_fee
        is_discount_item
        bundle_order_item_id
        bundleOptionOrderItems {
          id
        }
        sku {
          alias
        }
        sku_snapshot {
          alias
        }
      }
      display_subtotal
      display_total_tax
      display_delivery_fee
      display_discount
      display_grand_total
    }
  }
`;

export const getOrderStatusLogs = gql`
  query getOrderStatusLogs($orderId: Int) {
    getOrderStatusLogs(orderId: $orderId) {
      is_booking
      delivery_info {
        delivery_date
        delivery_time
        delivery_address
      }
      last_order_log_info {
        status
        created_at
        reason
        changed_by
        changed_by_user_type
        images
        tracking_link
        app_name
        reason_type
      }
      logs {
        status
        created_at
        reason
        changed_by
        changed_by_user_type
        updated_by
        updated_by_user_id
        updated_by_username
        updated_by_first_name
        updated_by_last_name
        images
        tracking_link
        app_name
        reason_type
        delivery_info_changed {
          name
          new_value
          previous_value
        }
        amends {
          type
          reason
          app_name
          product_name
          price_changed
          reason_type_id
          reason_type_name
          is_catch_weight
          halal
          image
          total_qty
          total_weight
          info_changed {
            name
            new_value
            previous_value
          }
        }
      }
    }
  }
`;

export const getOrderBlockReasons = gql`
  query getOrderBlockReasons($orderId: Int!) {
    getOrderBlockReasons(orderId: $orderId) {
      id
      order_id
      block_reason
      value
      limit_value
    }
  }
`;
