import gql from 'graphql-tag';

export const saleGetOrders = gql`
  query saleGetOrders(
    $tenantId: Int!
    $statusIds: [Int]
    $tlTaskStatusIds: [Int]
    $customerBuyerIds: [Int]
    $startDeliveryDate: String
    $endDeliveryDate: String
    $limit: Int
    $offset: Int
    $blockStatus: [OrderBlockStatus]
    $orderId: Int
  ) {
    saleGetOrders(
      tenantId: $tenantId
      statusIds: $statusIds
      tlTaskStatusIds: $tlTaskStatusIds
      customerBuyerIds: $customerBuyerIds
      startDeliveryDate: $startDeliveryDate
      endDeliveryDate: $endDeliveryDate
      limit: $limit
      offset: $offset
      blockStatus: $blockStatus
      orderId: $orderId
    ) {
      data {
        id
        version
        total_price
        total_tax
        is_paid
        stripe_transaction_id
        delivery_date
        delivery_time
        delivery_status
        delivery_instruction
        updated_at
        created_at
        payment_type
        stripe_card_id
        reason_cancel
        reason_type_cancel
        reason
        description
        po_number
        credit_note
        app_name
        tenant_id
        is_booking
        block_status
        can_update_processed_order
        user {
          first_name
          last_name
        }
        CreatedBy {
          first_name
          last_name
        }
        orderStatus {
          name
        }
        orderLogs {
          description
          user {
            user_type_id
          }
        }
        orderItems {
          id
          sku_id
          invoice_id
          is_delivery_fee
          is_discount_item
          customer {
            id
            name
            account_number
            minimum_order
          }
          sku {
            id
            product {
              id
              name
              discoverability
            }
          }
        }
        paymentTransactionItems {
          id
          order_id
          invoice_id
          payment_transaction_id
          amount
          created_at
          paymentTransaction {
            id
            checkout_session_id
            payment_intent_id
            amount
            payment_link
            payment_type_id
            status
            expires_at
            created_at
            created_by
          }
        }
        display_total_price
        display_total_tax
        display_price
      }
    }
  }
`;

export const saleGetOrderItemDetail = gql`
  query saleGetOrderItemDetail($orderId: Int!) {
    saleGetOrderItemDetail(orderId: $orderId) {
      id
      invoice_id
      order_id
      tenant_name
      tenant_id
      sku_id
      user_id
      customer_buyer_id
      order_item_status_id
      order_item_status
      customer_seller_id
      amount_qty
      total_qty
      origin_unit_price
      sale_unit_price
      total_price
      tax
      catch_weight
      active
      cancelled
      sku_name
      product
      oom
      image
      user_name
      mobile
      stripe_card_id
      is_catch_weight
      is_order_by_weight
      delivery_status
      delivery_address
      dispute_status
      dispute_reason
      dispute_remedy
      customer_name
      alias_name
      minimum_order
      hub
      description
    }
  }
`;

export const saleGetCustomersOrders = gql`
  query saleGetCustomersOrders(
    $searchQueries: String
    $isIncludeTotalCount: Boolean
    $limit: Int
    $offset: Int
    $tenantId: Int!
  ) {
    saleGetCustomersOrders(
      searchQueries: $searchQueries
      isIncludeTotalCount: $isIncludeTotalCount
      limit: $limit
      offset: $offset
      tenantId: $tenantId
    ) {
      totalCount
      customers {
        id
        name
        account_number
      }
    }
  }
`;

export const orderItemDisputes = gql`
  mutation Mutation($orderItemDisputesPayload: OrderItemDisputesInput!) {
    orderItemDisputes(payload: $orderItemDisputesPayload) {
      id
      order_id
      invoice_id
      sku_id
      product
      oom
      image
      user_id
      customer_buyer_id
      order_item_status_id
      catch_weight
      alias_name
      customer_seller_id
      amount_qty
      origin_unit_price
      sale_unit_price
      total_qty
      total_price
      tax
      active
      minimum_order
      hub
      user_name
      mobile
      stripe_card_id
      tenant_id
      tenant_name
      delivery_status
      cancelled
      dispute_status
      dispute_reason
      dispute_remedy
      delivery_address
    }
  }
`;

export const getInvoiceList = gql`
  query saleGetInvoiceList($id: Int!) {
    saleGetInvoiceList(id: $id) {
      invoice_id
      order_id
      invoice_number
      currency_code
      currency_symbol
    }
  }
`;

export const sendInvoiceToPeppolByOrderId = gql`
  mutation sendInvoiceToPeppolByOrderId(
    $orderId: Int!
    $tenantLegalEntityId: String
    $customerPeppolId: String
    $customerPeppolScheme: String
    $sendOnly: Boolean
  ) {
    sendInvoiceToPeppolByOrderId(
      orderId: $orderId
      tenantLegalEntityId: $tenantLegalEntityId
      customerPeppolId: $customerPeppolId
      customerPeppolScheme: $customerPeppolScheme
      sendOnly: $sendOnly
    )
  }
`;

export const getCustomerPeppol = gql`
  query getCustomerPeppol($id: Int!) {
    getCustomerPeppol(id: $id) {
      id
      peppol_id
      peppol_scheme_id
      peppol_scheme_code
      peppol_scheme
    }
  }
`;

export const getTenantIntegrationStorecove = gql`
  query getTenantIntegrationStorecove($tenantId: Int!) {
    getTenantIntegrationStorecove(tenantId: $tenantId) {
      id
      legal_entity_id
      peppol_id
      peppol_scheme_id
      send_only
      tenant_id
      created_at
      created_by
      updated_at
      updated_by
      active
    }
  }
`;

export const saleAddOrder = gql`
  mutation saleAddOrder(
    $customerId: Int!
    $deliveryAddressId: Int
    $billingAddressId: Int
    $cartItems: [SaleAddOrderItemInput]!
    $isBooking: Boolean
    $delivery_instruction: String
    $isUsingPickupAddress: Boolean
    $appliedCustomerCredit: Float
  ) {
    saleAddOrder(
      customerId: $customerId
      deliveryAddressId: $deliveryAddressId
      billingAddressId: $billingAddressId
      cartItems: $cartItems
      isBooking: $isBooking
      delivery_instruction: $delivery_instruction
      isUsingPickupAddress: $isUsingPickupAddress
      appliedCustomerCredit: $appliedCustomerCredit
    ) {
      id
      token
      blockStatus
      blockMessage
      isBlockedByExceedRemainingCreditLimit
      isBookingOrder
    }
  }
`;

export const saleUpdateOrder = gql`
  mutation saleUpdateOrder(
    $orderId: Int!
    $orderVersion: Int!
    $deliveryDate: String
    $deliveryTime: String
    $description: String
    $deliveryInstruction: String
    $poNumber: String
    $orderItems: [SaleUpdateOrderItemInput]!
    $reasonTypeId: Int
    $reason: String
  ) {
    saleUpdateOrder(
      orderId: $orderId
      orderVersion: $orderVersion
      deliveryDate: $deliveryDate
      deliveryTime: $deliveryTime
      description: $description
      deliveryInstruction: $deliveryInstruction
      poNumber: $poNumber
      orderItems: $orderItems
      reasonTypeId: $reasonTypeId
      reason: $reason
    ) {
      id
      token
    }
  }
`;

export const saleCancelOrder = gql`
  mutation saleCancelOrder($orderId: Int!, $orderVersion: Int!, $reasonTypeId: Int!, $reason: String) {
    saleCancelOrder(
      orderId: $orderId
      orderVersion: $orderVersion
      reasonTypeId: $reasonTypeId
      reason: $reason
    ) {
      id
      order_status_id
      active
    }
  }
`;

export const saleCheckAllowDoubleOrder = gql`
  mutation saleCheckAllowDoubleOrder {
    saleCheckAllowDoubleOrder
  }
`;

export const saleGetTotalCountOrder = gql`
  query saleGetTotalCountOrder(
    $tenantId: Int!
    $statusIds: [Int]
    $tlTaskStatusIds: [Int]
    $customerBuyerIds: [Int]
    $startDeliveryDate: String
    $endDeliveryDate: String
    $blockStatus: [OrderBlockStatus]
  ) {
    saleGetTotalCountOrder(
      tenantId: $tenantId
      statusIds: $statusIds
      tlTaskStatusIds: $tlTaskStatusIds
      customerBuyerIds: $customerBuyerIds
      startDeliveryDate: $startDeliveryDate
      endDeliveryDate: $endDeliveryDate
      blockStatus: $blockStatus
    )
  }
`;

export const saleGetReasonType = gql`
  query getReasonType($active: Boolean) {
    getReasonType(active: $active) {
      id
      name
      active
    }
  }
`;

export const getFluidCustomerInfo = gql`
  query getFluidCustomerInfo($tenantId: Int!, $customerId: Int!) {
    getFluidCustomerInfo(tenantId: $tenantId, customerId: $customerId)
  }
`;
