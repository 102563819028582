const en = {
  Available: '可用',
  NPS_text: '您向朋友推荐我们的应用程序的可能性有多大？',
  NPS_thank_you: '感谢反馈！',
  OK: '确定',
  Person_type_cannot_be_empty: '人员类型不能为空',
  Weights: '重量',
  about_app: '应用程序简介',
  about_this_product: '关于本产品',
  about_treedots: 'TreeDots 简介',
  about_us: '关于我们',
  access_denied: '访问被拒绝。',
  access_signup_des1: '看来您还没有开设账户。',
  access_signup_des2: '请创建一个账户，或者 ',
  access_signup_des3: '添加到购物车。',
  access_signup_terms: '本人已阅读并同意 ',
  register_account_type: 'You are registering as:',
  personal: 'Personal',
  business: 'Business',
  register_personal: 'To purchase for personal use',
  register_business: 'To purchase for business use',
  account: '帐户',
  accountPage: {
    add_card: '添加新的银行卡',
    add_nets_card_type: '添加 NETS 银行卡',
    added_card_successfully: '成功添加卡片!',
    are_you_sure_delete_card: '确定要删除这张信用卡吗？',
    are_you_sure_delete_card_nets: '确定要删除这张 NETS 银行卡吗？',
    cannot_be_empty: '不能为空',
    card_has_been_removed: '卡片已移除',
    card_number: '卡号',
    change: '更改',
    configure_peppol_identifier: '配置 Peppol 标识符',
    confirm_new_password: '确认新密码',
    confirm_new_password_cannot_be_empty: '确认新密码不能为空',
    confirm_password_does_not_match: '确认密码不匹配',
    cvc_cvv: 'CVC/CVV',
    cvv: 'CVV',
    default: ' 默认',
    default_card_has_been_changed: '默认卡已更改',
    delete_card: '删除卡片',
    dont_have_card: '没有银行卡？点击这里申请并获得 200 美元的现金返还！',
    edit_card: '编辑卡片',
    enter_current_password: '输入当前密码',
    enter_new_password: '输入新密码',
    expiry_date: '有效期',
    manage_companines: '管理公司',
    must_be_a_peppol_identifier: '必须是 peppol 标识符',
    must_be_a_valid_number: '必须是有效数字',
    nets_card_error_instruction:
      'NETS 银行卡不适用于此设备，我们将清除此设备上的所有 NETS 银行卡，请重新添加新卡',
    nets_card_error_title: 'NETS 银行卡错误',
    nets_card_not_allow: 'NETS 银行卡仅支持一个租户付款',
    nets_card_type: '（NETS 银行卡）',
    new_password_cannot_be_empty: '新密码不能为空',
    old_password_cannot_be_empty: '旧密码不能为空',
    old_password_doesnt_match: '旧密码不匹配',
    password: '密码',
    password_must_be_at_least_6_characters: '密码必须至少为 6 个字符',
    peppol_id_configuration: 'Peppol ID 配置',
    peppol_id_configured_successfully: 'peppol ID 配置成功',
    peppol_identifier: 'Peppol 标识符',
    peppol_scheme: 'Peppol 计划',
    peppol_scheme_description: 'Peppol 计划说明',
    please_choose_something: '请选择一项',
    product_sku_name: '商品 Sku 名称',
    remarks: '备注',
    remove: '移除',
    request_product: '申请产品',
    request_product_success: '申请产品成功',
    set_as_default: '设为默认',
    supplier_name: '供应商名称',
    validity_period: '有效期',
    your_password_has_been_saved: '您的密码已保存'
  },
  account_type: '账户类型',
  add: '添加',
  addCompany: {
    accept: '接受',
    accept_company_request: '接受公司申请',
    companies_that_assigned: '指定您为主要联系人的公司。请查看并接受或拒绝。',
    have_been_notified: '已收到您的操作通知，我们的运营团队成员将尽快与您联系以确认此操作，然后再将您移除',
    key_contact_person: '主要联系人。',
    my_companies: '我的公司',
    pending_your_action: '等待您的操作',
    reject_company_request: '拒绝公司请求',
    success_assigned: '分配成功',
    success_rejected: '拒绝成功',
    there_are: '有',
    would_you_like: '您是否想确认自己是',
    would_you_like_to_reject_being: '您是否想拒绝成为',
    you_are_now: '您现在',
    you_can_now_make_order: '您现在可以下订单'
  },
  add_address: '添加地址',
  add_another_card: '添加另一张卡片',
  add_card_failed: {
    card_number_invalid: '请输入有效卡号。',
    cvc_invalid: '请输入有效的 CVC/CVV 号码',
    expired_card: '此卡片已过期。请使用另一张卡重试。',
    expired_date_invalid: '请输入有效的到期日期 （月/年）',
    general: '您的卡片似乎有问题。请使用另一张卡片重试，或联系银行寻求进一步的帮助。',
    invalid_account: '此卡片无效。请添加其他卡或联系银行以获得进一步的帮助。',
    stolen_card: '您的卡片似乎有问题。请联系银行以获得进一步的帮助。'
  },
  add_card_or_credit_card: '添加借记卡/信用卡',
  add_card_success: '成功添加新卡！',
  add_card_validation: {
    card_number: '输入有效的 16 位数卡号',
    cvv: '无效的 3 位数 CVV',
    expiry_date: '月/年',
    invalid_month: '无效月份'
  },
  add_cuisine: '添加菜肴',
  add_cust_credit_to: '将客户积分添加至：',
  add_delivery_instructions_or_directions: '在此处输入送货说明或指示',
  add_self_pickup_instructions: '添加自提说明',
  add_file: '添加文件',
  add_item_cart: '将商品添加到购物车',
  add_items_to_favorite_tab: '添加到收藏夹，方便以后更快下单和结账。',
  add_new_address: '添加新地址',
  add_new_card: '添加新卡',
  add_new_company: '添加新公司',
  add_order_remark: '添加订单备注',
  add_product_to_favorite: '将产品加入收藏夹？',
  add_to_cart: '加入购物车',
  adding_card_failed: '添加卡片失败',
  additional_info: '理由',
  additional_options: '其他选项',
  address: '地址',
  address_btn_remove: '删除地址',
  address_form: {
    city: {
      required: '城市不能为空'
    },
    country: {
      required: '国家/地区不能为空'
    },
    postal_code: {
      required: '邮政编码不能为空'
    },
    road: {
      required: '街道不能为空'
    },
    state: {
      required: '州省不能为空'
    }
  },
  address_remove_confirm: '删除此地址？',
  address_unable_delete_default: '无法删除默认账单地址',
  ago: '之前',
  alert_blacklist_order: 'alert-blacklist-order',
  alert_dulicate_order: 'alert-duplicate-order',
  alert_error_header: '错误',
  all: '所有',
  all_collection_point: '所有取货点',
  all_companies: '所有公司',
  all_consumers: '所有公司',
  all_invoices: '所有发票',
  all_is_un_selected: '全部未选中',
  all_notifications: '所有通知',
  all_products: '所有产品',
  all_rights_reserved: '保留所有权利',
  allow: '允许',
  allow_location_access: '允许访问位置信息',
  already_has: '已有',
  already_have_an_account: '已有帐户？',
  amount_due: '应付金额',
  amount_due_error: '应付金额不能大于或等于',
  an_order_with: '向……订购',
  and: '和',
  applied: '已应用',
  applied_credits: '应用积分',
  apply: '使用',
  apply_available_credit: '申请可用积分',
  apply_filter: '使用筛选条件',
  apply_search_filter: '应用筛选条件',
  apply_treedots_voucher: '使用 TreeDots 优惠券',
  apply_voucher: '使用优惠券',
  approve: '批准',
  approved: '已批准',
  approvedBy: '批准人',
  approvedPrice: '批准价格',
  are_you_sure: '是否确定？',
  available_credits: '可用积分',
  available_variation: '可用的变化',
  available_vouchers: '可用优惠券',
  available_vouchers_for_you: '为您提供的优惠券',
  awaiting_payment: '等待付款',
  back: '返回',
  back_order: '延期订单',
  back_to_account: '返回账户页面',
  back_to_home: '返回首页',
  back_to_invoice: '返回发票清单',
  back_to_notifications: '返回通知',
  back_to_top: '返回页首',
  back_to_try_again: '返回重试',
  back_try_again: '返回以重试',
  bank_transfer: '银行转帐',
  bank_transfer_detail: '银行转账详情',
  bargain_price: '价格实惠',
  began: '一切是如何开始的',
  better_experience: '我们正在更新应用程序，为您提供更好的体验',
  bill_name_and_address: '账单名称和地址',
  billing_address: '帐单邮寄地址',
  billing_information: '账单信息',
  birth_date: '出生日期',
  birth_date_failed: '出生日期更新失败！',
  birth_date_updated: '出生日期更新成功！',
  black_listed: '已添加至黑名单',
  booking: '预订',
  booking_added: '预订已添加！',
  booking_completed_status: '预订完成',
  booking_confirmed: '预订已确认',
  booking_date: '预订日期',
  booking_has_been_confirmed: '您的预订已获得确认！',
  booking_lowcase: '预订',
  bookings: '预订',
  branches: '分行',
  browse: '浏览',
  browse_message_if_cart_empty: '我们的食品，在这里添加您的商品！',
  browse_more: '浏览更多商品 ',
  browse_product: '浏览产品',
  browse_your_business: '开始浏览您的业务',
  building_name: '建筑物名称',
  cache_cleared: '缓存已清除。',
  camera: '相机',
  cancel: '取消',
  cancelOrder: '取消订单',
  cancel_button: 'cancel-button',
  cancel_leave_community_chat: '取消',
  cancel_order_item_hint: '团购成功后，您无法取消商品',
  cancel_payment: '取消付款',
  cancel_payment_desc: '付款将被取消',
  cancel_payment_success: '您的付款已取消',
  cancel_pre_order: '取消预订',
  cancel_remove_product_form_cart: '不，保留这件商品',
  cancel_submitted_payment: '取消已提交的付款？',
  cancel_submitted_payment_desc: '您提交的付款仍在等待批准。一旦取消，此操作将无法撤销',
  cancellation_reason: '取消原因',
  cancelled_by: '取消者',
  cancelled_by_supplier: '{supplierName} 已取消您的 {isOrderBooking}',
  cancelled_item: '您取消了一件商品。',
  cannot_connect: '无法连接',
  cannot_create_problem: '目前无法创建您的请求，请重试',
  cannot_find_product: '很抱歉，找不到此产品。',
  cant_ordered: '抱歉，有些商品无法再订购。我们已将它们从您的订单中移除。',
  cap_grand_total: '总计',
  card: '卡片',
  card_default_failed: '设为默认卡失败！',
  card_default_success: '已成功设为默认卡！',
  card_has_been_deleted: '成功移除银行卡！',
  card_has_been_updated: '成功更新银行卡！',
  card_list: '卡片列表',
  card_not_selected: '未选中',
  card_popover: 'card-popover',
  card_unset_default_failed: '取消默认卡失败！',
  card_unset_default_success: '已成功取消默认卡！',
  cart: '购物车',
  cart_error_title: '卡片错误!',
  carton: '纸箱',
  carton_deal: '纸箱交易',
  cash: '现金',
  cash_collection_date: '现金收款日期',
  cash_deposit: '现金存款',
  cash_deposit_detail: '现金存款详情',
  cash_detail: '现金明细',
  cash_on_delivery: '货到付款',
  cash_payment_collection: '现金支付（收款）',
  cash_payment_collection_detail: '现金支付（收款）详情',
  catch_weight: '无体重限制',
  catch_weight_cap: '称重',
  categories: '类别',
  category_sub: '类别/子类别',
  cc_add_card: '添加新卡',
  cc_card_number: '卡号',
  cc_cvv: 'CVV',
  cc_expiry_date: '到期日期',
  cc_name_on_card: '持卡人姓名',
  change: '更改',
  change_address: '更改地址',
  change_delivery_time: '更改交付时间',
  change_order_item_status_confirmation: '是否要更新商品订单状态？',
  change_password: '更改密码',
  change_profile_photo: '更换头像',
  change_the_address: '更改地址',
  change_time: '更改时间',
  channel_admin: '渠道管理',
  chat: '在线交谈',
  chat_mute_notification: '消息免打扰',
  chat_muted: '聊天已静音',
  chat_no_description: '该取货点的团长尚未添加群组描述。请继续关注!',
  chat_participants: '成员',
  chat_unmuted: '聊天未静音',
  chats: '聊天室',
  check_birtday_val: 'updateProfile.birth_date_cannot_be_empty',
  check_email_val: 'updateProfile.email_cannot_be_empty',
  check_fname_length: 'first_name_cannot_be_empty',
  check_fname_length_limit: 'first_name_must_be_at_least_2_characters',
  check_gender_val: 'updateProfile.gender_cannot_be_empty',
  check_phone_number: '电话号码已存在。',
  check_phone_number_error: '电话号码无效。',
  check_phone_number_length_val: 'updateProfile.phone_number_must_be_at_least_5_characters',
  check_phone_number_val: 'phone_number_cannot_be_empty',
  check_search: '请检查您的',
  check_valid_close_time: '此字段不能为空',
  check_valid_email: 'this_field_must_be_a_valid_email',
  check_valid_open_time: '此字段不能为空',
  checkout: '结账',
  checkout_loader: {
    back_to_checkout: '返回结账',
    cancel_transaction: '您已取消交易。',
    change_payment: '更改付款方式',
    contact_support: '联系技术支持',
    continue: '继续',
    fail: '您的付款未成功。<br>请重试！',
    failure: {
      card_not_supported: '此次交易不支持此卡。请使用另一张卡片重新结账。',
      card_velocity_exceeded: '超过了卡片的限额。请用另一张重试并完成结账。',
      error: '出了点问题…我们在加载此页面时遇到问题。请重试。',
      expired_card: '您的卡已过期。请使用另一张卡片重试。',
      general: '您的银行卡似乎有问题。请使用另一张卡重试并完成交易，或联系银行寻求进一步的帮助。',
      insufficient_e_wallet_balance: '您的余额不足。请确保您的 TreeDots Pay 中有足够的金额，然后重试。',
      insufficient_funds: '您的余额不足。请确保您的付款方式有足够的余额或选择其他方式。',
      invalid_account: '您的卡已失效。请使用另一张卡重试。',
      processing_error: '处理此卡时出错。请尝试再次结账。'
    },
    insufficient: '余额不足。',
    loading: '请稍候！<br>我们正在处理您的付款…',
    not_you_1: '是服务器出了问题，与您无关',
    not_you_2: '请联系我们的',
    not_you_3: '技术支持团队',
    not_you_4: '寻求帮助！',
    or_reach_1: '或者联系我们的',
    or_reach_2: '支持团队',
    or_reach_3: '请求帮助',
    payment_canceled: '付款已取消',
    payment_failed: '付款失败！',
    review_checkout: '查看结账商品',
    review_payment: '查看付款方式',
    success: '已成功付款！',
    try_another_method: '试试其他付款方式？'
  },
  checkout_pre_order: '支付预购订单',
  cheque: '支票',
  cheque_date: '支票存款日期',
  cheque_detail: '支票详情',
  chinese_simplified: '简体中文',
  chinese_traditional: '繁体中文',
  choose_card: '需要选择卡片',
  choose_hub: '搜索取货点',
  choose_hub_btn: '选择取货点',
  choose_product: '需选择产品',
  choose_server: '选择服务器',
  choose_sign_in_method: '选择登录方式',
  city: '城市',
  city_cannot_be_empty: '城市不能为空',
  clear_cache: '清除缓存',
  clear_cache_confirm: '是否确定清除缓存？',
  click_here: '请点击这里',
  close: '关闭',
  code: '代码',
  code_has_been_sent: '验证码已发送！',
  collect_at: '取货地点',
  collection_date: '预计取货日期：%{date}',
  collection_details: '取货详情',
  collection_note: '*取货日期将在取货点达到%{currency_symbol}%{minimum_order}后确认。',
  collection_point_description: '取货点位于您的社区内以及您可以执行以下操作的其他地点：',
  collection_point_not_supported: '很抱歉。此供应商不会配送至您选择的取货点。',
  collection_point_unsupported: '取货点不支持',
  collection_points_near_you: '您附近的取货点',
  coming_soon: '即将推出！',
  community: '社区',
  community_description_label: '社区描述',
  companies: '公司',
  companies_with_key_contact: '公司及主要联系人',
  companies_with_postal_code: '公司及邮政编码',
  company: '公司',
  companyDoesNotExit: '公司不存在',
  company_in_review: '公司正在审核中',
  company_request: '公司请求',
  company_updates: '公司动态',
  competitor_price: '竞争对手的价格',
  competitor_price_feedback: '竞争对手价格反馈',
  confirm: '确认',
  confirm_CP_modal: '加入我们的中心社区，接收有关订单和配送情况的更新状态！',
  confirm_CP_title: '欢迎来到宏茂桥中心',
  confirm_button: 'confirm-button',
  confirm_cancel: '确认取消',
  confirm_cancel_order: '是否确定要取消此订单？',
  confirm_cancel_payment: '是，取消我的付款',
  confirm_email: '确认新电子邮箱',
  confirm_increased_quantity: '确认增加的数量',
  confirm_order_5_mins: '创建于 5 分钟内，是否要提交此新订单？',
  confirm_order_cancel: '确认取消订单',
  confirm_order_update: '确认更新订单',
  confirm_password: '确认新密码',
  confirm_password_cannot_be_empty: '确认密码不能为空',
  confirm_password_does_not_match: '确认密码不匹配',
  confirm_password_new: '确认密码',
  confirm_remove_product_form_cart: '是，移除此商品',
  confirm_send_inv_email: '我们会将发票发送到您的邮箱，是否继续？',
  confirm_submission: '确认提交',
  confirm_update_order: '是否确定要更新此预订？',
  confirm_update_order_mess: '是否确定要更新此订单？',
  confirmation: '确认',
  connect: '连接',
  connectSupplier: {
    connected: '已连接\n',
    payment: '付款',
    supplier: '供应商',
    supplier_not_found: '未找到供应商'
  },
  connectToOtherSuppliers: '连接其他供应商',
  connected: '已连接',
  not_connected: '未连接',
  connection_issue_message: '无法加载 - 请确保您已连接到 Internet，然后重试',
  contact: '联系',
  contact_p1: '对于印度尼西亚的客户',
  contact_p2: "客服邮箱：hello.id{'@'}thetreedots.id",
  contact_p3: 'WhatsApp：+62 821 10001 778',
  contact_p4: '消费者投诉服务',
  contact_p5: '消费者保护和贸易合规总局',
  contact_p6: '印度尼西亚贸易部',
  contact_p7: 'WhatsApp：0853-1111-10101',
  contact_supplier: '联系供应商',
  contact_supplier_to_purchase: '请联系您的供应商购买此产品！',
  contact_to_purchase: '联系购买',
  contact_us: '联系我们',
  contact_your_supplier: '请联系您的供应商！',
  continue: '继续',
  continue_order: '您将要下单，是否继续？',
  cooking: '烹饪',
  copy: '复制',
  country: '国家/地区',
  country_code: '国家/地区代码',
  country_of_origin: '原产地',
  consumers: '消费者',
  cp_description_point_1: '方便与其他买家团购',
  cp_description_point_2: '选择不同供应商的产品',
  cp_description_point_3: '在配送时领取您的杂货',
  create_as_booking: '创建为预订',
  create_booking: '创建预订',
  create_order: '创建订单',
  credit_block: '信用冻结',
  credit_control_reached: '已达到供应商设置的积分控制参数',
  credit_limit_exceeded: '超出信用额度',
  uncertainty_block_header: `在订单送货日期前有未结发票需支付`,
  credit_note: '信用票据 ({creditCode})',
  credit_term: '信用期限',
  crop_image: '裁剪图像',
  cuisine: '菜肴',
  cuisine_collection: {
    bak_kut_teh: '肉骨茶',
    bakery_cake: '烘焙食品和蛋糕',
    burgers: '汉堡',
    chicken_rice: '鸡肉饭',
    chinese: '中式菜肴',
    claypot: '煲仔饭',
    cze_char: '煮炒',
    fast_food: '快餐',
    fish_soup: '鱼汤/板面',
    fishball_noodle: '鱼丸面',
    fried_chicken: '炸鸡',
    fried_rice: '炒饭',
    india_north: '印度菜肴（北部）',
    india_south: '印度菜肴（南部）',
    indonesia: '印度尼西亚',
    indonesian: '印尼菜肴',
    japan: '日本',
    japanese: '日本菜',
    korea: '韩国菜',
    local_delights: '当地美食',
    lor_mee: '卤面',
    malay: '马来菜',
    malaysia: '马来西亚',
    mamak: 'Mamak',
    martabak: '马塔巴克',
    mix_veget_rice: '杂菜饭',
    noodles: '面条',
    others: '其他',
    pizza: '披萨',
    porridge: '稀饭/米粥',
    prawn_noodle_soup: '虾面汤',
    rice: '稻米',
    satay: '沙嗲',
    seafood: '海鲜',
    snack: '点心',
    soup: '汤',
    thai: '泰国菜',
    vietnamese: '越南菜',
    western: '西式菜肴'
  },
  cuisine_preference: '菜肴偏好',
  cuisine_preference_optional: '菜肴偏好（选填）',
  customerPage: {
    account_number: '账号',
    add_address: '添加地址',
    add_company: '添加新公司',
    address: '地址',
    address_has_been_added: '地址已添加！',
    address_has_been_updated: '地址已更新！',
    address_saved: '地址已保存！',
    bank_transfer: '银行转账',
    billing_address: '帐单地址',
    branch: '分行',
    business_information: '商业信息',
    account_information: '账户信息',
    cannot_load_image: '无法加载图像',
    cod: '货到付款',
    collection: '取货',
    company: '公司',
    company_has_been_updated: '公司已更新！',
    company_information: '公司信息',
    company_information_saved: '公司信息已保存！',
    company_name: '公司名称',
    company_name_cannot_be_empty: '公司名称不能为空',
    company_name_must_be_at_least_4_characters: '公司名称必须至少包含 4 个字符',
    credit_term: '信用期限',
    credit_term_cannot_be_empty: '信用期限不能为空',
    customer: '顾客',
    customer_email_optional: '顾客电子邮箱（可选）',
    customers: '顾客',
    days: '天',
    days_lower: '天',
    delete_person: '删除人员',
    delivery_charge: '运费',
    delivery_hours: '配送时间',
    delivery_hours_has_been_updated: '配送时间已更新！',
    delivery_hours_saved: '配送时间已保存',
    delivery_instruction: '配送说明',
    details: '详情',
    edit_information: '编辑信息',
    end_month: '月底',
    end_week: '周末',
    enter_a_location: '输入一个位置',
    enter_building_name: '输入建筑物名称',
    enter_city: '输入城市',
    enter_floor_no: '输入楼层号',
    enter_new_company_name: '输入新的公司名称',
    enter_postal_code: '输入邮政编码',
    enter_stall_no: '输入摊位编号',
    enter_street_address: '输入街道地址',
    enter_unit_no: '输入单元号',
    enter_your_place: '输入您的位置',
    existing_account: '现有账户',
    find_customer: '查找顾客',
    image: '图像',
    image_has_been_added: '图像已添加',
    image_has_been_updated: '图像已更新',
    image_optional: '图像（可选）',
    images: '图像',
    minimum_order: '最小订单量',
    minimum_order_quantity: '最小起订量',
    new_account: '新帐户',
    no_branches: '无分行',
    no_cash_no_delivery: '货到付款，不收取现金',
    person_has_been_added: '人员已添加！',
    person_has_been_deleted: '人员已删除！',
    person_has_been_updated: '人员已更新！',
    person_information: '人员信息',
    person_information_saved: '人员信息已保存！',
    position_cannot_be_empty: '位置不能为空',
    profile_cannot_be_empty: '个人资料不能为空',
    select_a_profile: '选择个人资料',
    statement: '声明',
    sub_buyer: '分买方',
    sub_buyer_has_been_added: '分买方已添加！',
    sub_buyers: '分买方',
    thirty_days: '（30 天）',
    upload_company_logo: '上传公司标志',
    week_plus_2: '到本周结束 + 2天',
    customer_does_not_have_soa_receiver: `This customer doesn't have any SOA receiver`
  },
  customer_balance: '客户余额',
  customer_instruction: '客户送货说明',
  dashboard: '操作面板',
  date_purchased_new_to_old: '购买日期（从新到旧）',
  date_purchased_old_to_new: '购买日期（从旧到新）',
  day: '天',
  days: '{day} 天',
  default: '默认',
  default_billing_address: '默认的账单地址',
  default_group_description: '该取货点的团长尚未添加群组描述。请继续关注!',
  delete: '删除',
  delete_account: '删除帐户',
  delete_account_agree: '同意并删除帐户',
  delete_account_button: '删除我的账户',
  delete_account_confirmation: '帐户将被删除，您将失去对它的所有访问权限。此操作无法撤消。',
  delete_account_go_back: '返回',
  delete_account_text1: '我们很遗憾看到您离开',
  delete_account_text2: '确定永久删除帐户？',
  delete_account_text3: '请确认，一旦删除：',
  delete_account_text4:
    '我们只会保留您的帐户 30 天，然后您的帐户将被永久删除。如果您在 30 天内随时改变主意，只需重新登录即可重新激活您的帐户。',
  delete_account_text5:
    '您的所有数据、以往的交易记录、剩余的优惠券和电子钱包资金将被永久删除，您将无法再访问您的帐户。',
  delete_account_title: '确定吗？',
  delete_card: '删除银行卡？',
  delete_chat_confirm: '确定要离开当前聊天吗？',
  delete_items: '删除商品',
  delete_message: '此消息已删除',
  delete_message_confirm: '确定删除此消息？',
  delete_this_item: '删除此项？',
  delivered: '已送达',
  delivered_success: '您的 {isOrderBooking} 商品已成功送达！',
  delivery: '配送',
  deliveryTimeOptional: '配送时间（可选）',
  delivery_address: '配送地址',
  self_pickup: '自提',
  self_pickup_address: '自提地址',
  delivery_date_time: '配送日期和时间',
  self_pickup_date_time: '自提日期和时间',
  delivery_failed: '抱歉，配送失败',
  delivery_information: '配送信息',
  delivery_instruction_hint: '例如：为该客户的所有订单输入常行指示',
  delivery_started: '已发货',
  delivery_status: '交付状态',
  delivery_successful: '交付成功',
  delivery_time: '配送时间',
  self_pickup_time: '自提时间',
  deposit_date: '现金存款日期',
  desc_unavailable_product: '您选择的取货点不出售这些产品。',
  description: '描述',
  desired_solution: '期望的解决方案',
  developer_menu: '开发人员菜单',
  diary: '日记',
  did_not_receive_code: '没有收到验证码？',
  digital_payment_method: '数字支付方式',
  direct_price: '直接价格',
  discount: '折扣',
  discount_voucher: '折扣券',
  dismiss: '忽略',
  dispute_select_reason: '选择问题的原因',
  dispute_select_remedy: '选择补救措施',
  dispute_selected_reason: '选定的问题原因',
  dispute_selected_remedy: '选择的补救措施',
  dispute_status_declined: '已拒绝',
  dispute_status_processing: '正在处理',
  dispute_status_resolved: '已解决',
  dispute_status_unknown: '未知',
  distance: '距离',
  do_you_have_any_feedback: '您有什么反馈吗？（选填）',
  do_you_want_merge: '是否要将这 {orderCount} 个商品合并到现有订单 #{orderNumber}？',
  do_you_want_proceed: '是否仍要继续？',
  document: '文档',
  done: '已完成',
  dont_have_account: '没有帐户',
  dont_have_an_account: '没有帐户？',
  dont_have_pwd: '还没有密码？',
  download_invoice: '下载发票',
  downloading: '下载中',
  drinks: '饮品',
  drn_delayed_delivery: '配送延迟',
  drn_goods_spoild: '商品变质',
  drn_missing_item: '缺少商品',
  dry_no_remedy: '在没有任何补救措施的情况下接受商品',
  dry_request_credit: '申请退货凭证',
  dry_request_exchange: '申请 1:1 兑换',
  dry_request_pick_item: '请求取走发错的商品',
  due: '到期日期: {dueDate}',
  due_today: '今天到期',
  duplicate_order: '重复订单',
  e_wallet: '电子钱包',
  edit: '编辑',
  edit_card: '编辑卡片',
  edit_cusine: '编辑菜肴',
  edit_payment_amount: '编辑付款金额',
  edit_profile: '编辑资料',
  email: '电子邮箱',
  email_address: '电子邮件地址',
  email_already_registered: '此电子邮箱已注册',
  email_already_registered_please_login: '该电子邮件已被注册。请使用注册邮箱登录或使用新邮箱创建',
  email_alt_img: '电子邮件',
  email_cannot_be_empty: '电子邮箱不能为空',
  email_failed: '电子邮箱更新失败',
  email_link_to_share:
    'mailto:?subject=TreeDots%20App%20Download%20Link&amp;body=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload',
  email_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/mail-256.png',
  email_share_link: 'mailto:hello@thetreedots.com',
  email_updated: '电子邮箱更新成功！',
  email_username: '电子邮箱/用户名',
  empty_cart_1: '您的购物车是空的！',
  empty_cart_2: '浏览',
  empty_cart_3: '我们的食物，开始向我们订购！',
  encouraging: {
    content_1: '2021 年，我们的团购平台进行了扩展，为社区先锋服务终端消费者提供了解决方案。',
    content_2: '我们推出了',
    content_2a:
      '在这里，取货点负责人可以管理社区订单，接收供应商的大批量货物并获得佣金。您也可以通过注册我们的卖家中心应用程序成为负责人！',
    seller_app: '卖家中心应用，',
    title: '鼓励企业自主经营'
  },
  end_of_week: '周末',
  english: '英语',
  enter_address: '输入地址',
  enter_card_number: '输入卡号',
  enter_code: '输入代码',
  enter_country: '输入国家/地区',
  enter_desc_transaction: '输入关于此交易的描述。',
  enter_description: '输入描述（可选）',
  enter_state: '输入州/省',
  enter_voucher_code: '输入您的优惠券代码',
  enter_your_code: '输入您的验证码',
  enter_your_mobile_number: '输入您的手机号码，我们将向您发送验证码以重新登录您的帐户。',
  enter_your_mobile_number_to_set_pwd: '请输入您的手机号码，我们将向您发送验证码以设置新密码。',
  err_msg_color: '危险',
  err_query: '哎呀！发生错误，请稍后再试。',
  err_query_payment: '对不起！您的付款出了问题，请稍后再试。',
  err_searh: '没有搜索结果！',
  err_voucher_invalid: '优惠券代码无效！',
  errorContent: '抱歉，我们无法处理，请重试。',
  errorDefaultTime: '结束时间应晚于或等于开始时间',
  errorTitle: '哎呀！发生了错误',
  estimated_collection_date: '预计取货日期',
  ex_delivery_instruction: '例如：“为该客户的所有订单输入常行指示”',
  expire_soon: '即将过期',
  expired_date: '到期日期',
  expires: '已过期',
  facebook: 'Facebook',
  facebook_alt_img: 'facebook',
  facebook_link_to_share: 'https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fthetreedots.com%2Fdownload',
  facebook_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/facebook-256.png',
  facing_an_issue_chat_with_us_for_real_time_support: '遇到任何问题？与我们聊天以获得实时支持！',
  failed: '失败',
  failed_cancel: '取消商品失败。',
  failed_delivery: '配送失败',
  failed_to_fetch: '获取失败',
  failure_reason: '失败原因',
  faq: '常见问题',
  faq_page: {
    collections: '收藏',
    more: '更多',
    orders: '订单',
    p1_1:
      '为了在我们平台上购物，您首先需要下载我们的手机团购应用程序并注册个人账户。我们的应用程序目前可用于 IOS 和安卓系统。然后便可以开始浏览我们平台上的产品！',
    p1_2:
      '看到喜欢的东西了？选择附近的取货点，以便您购买并领取商品。将商品添加到您的购物车，选择付款方式，然后下订单——即可轻松进行购物！您的订单将出现在“订单”部分，您可以在其中查看购买状态，并了解取货时间。当您的团购订单达到最低团购限额时，供应商将安排送货——您会收到一封确认邮件，告诉您前往所选取货点取货的时间！',
    p1_3:
      '不要忘记访问我们的应用内聊天功能，向您的取货点负责人说“嗨”，他/她可能是您隔壁的邻居或来自您认识的小区！取货点老板将从供应商处收到您的货物，获得您订单的最新信息。请记得加入取货点社区聊天！其中汇集了像您这样的买家、朋友和邻居，他们会分享创意和食谱！',
    p2_1:
      '我们的平台提供团购购物体验，可以让您和友邻一起购物、省钱和节约粮食！当您在我们平台上购买产品时，您会自动加入到团购，每次购物都能为解决食物浪费问题做出贡献！',
    p2_2:
      '它是如何运作的？我们供应商列出的所有产品均为批量销售。但好消息是您不必单独购买大批量产品！个人买家只需购买所需数量的商品，我们会将这些商品与其他买家在团购中购买的商品合并。当买家购买的产品数量达到最低限额时，供应商会立即安排将所有商品运送到取货点。',
    p2_3:
      '通过这种方式，我们可以把价格压低，让您享受到高达一折的食品零售价格！供应商也不必提供散装产品或浪费库存，从而更有效地重新分配食品并减少大气中的碳排放。而且我们将社区聚集在一起！加入我们的应用内社区聊天，与您的取货点负责人和其他买家打个招呼吧！',
    p3_1:
      '如要加入取货点，请先在地图上搜索您所在地附近的取货点。您可以通过单击位置图标来访问地图。找到要购买和取货的取货点后，单击“确认”，开始浏览其中出售的产品！',
    p3_2:
      '当您加入取货点时，您将自动加入到取货点社区聊天中，您可以与负责人讨论您的订单和取货事宜或与其他买家聊天！想与您的负责人私下交谈吗？您也可进行一对一六天！如需访问所有聊天，请选择聊天图标。',
    p4_1:
      '您可以通过应用程序内“订单”部分来跟踪订单状态。可取货的商品将显示在“待取货”状态下，且展示已确认的取件日期。怕自己可能会错过消息吗？不用担心，在确认好您商品的取件日期后，我们将向您发送电子邮件和通知。而且在取件日当天，我们也会再次提醒您！',
    p4_2:
      '请注意，您订单中来自不同供应商的商品可能会在不同的时间送达，具体取决于团购的进度。如果您因无法在特定日期领取商品而想有所调整，请通过应用内聊天与您的取货点负责人联系。',
    p5_1:
      '如果未达到供应商的团购最低限额，您可以取消订单中的商品。您可以在“订单”部分查看产品的团购进度。状态为“待取货”且已确认取件日期的商品不能因改变主意而取消。订单取消后我们会立即处理退款。请注意，退款可能需要 7-10 个工作日才能显示在您的银行帐户中。有关取消和退款政策的完整信息，请查看我们的“退款”部分。',
    p6_1:
      '如果您的订单中有商品未能达成团购要求，我们会自动将您的商品移至下一个可行的团购日期，以便尝试与其他买家一起达成团购最低限额。您将收到电子邮件通知，其中包含您的商品的新预计取件日期。',
    p6_2:
      '如果您不想等待，且商品无法达到团购最低限额，您可以取消并退款。如果您想对订单进行任何修改，请通过应用内聊天联系您的取货点负责人。负责人可完全代表您管理订单，取消订单，并将您的订单移到另一个取件日期或到另一个取货点取货。',
    p7_1:
      '在极少数情况下，如果您购买的产品缺货，您的取货点负责人将为您处理退款。如果您收到供应商发来的有缺陷产品（劣质、过期等），请通过应用内聊天联系您的取货点负责人帮助您退款。退款可能需要 7-10 个工作日才会显示在您的银行帐户中。有关取消和退款政策的完整信息，请查看我们的“退款”部分。',
    p7_2: '部分。',
    p8_1:
      '您的购物车中的某些产品可能被标记为“不可购买”，因为这些产品的供应商无法向您选择的取货点送货。您可以选择供应商当前可送货的另一个取货点，并以新的取货点进行结算。想让特定的供应商向您最喜欢的取货点送货？通过我们的应用内聊天，与您的取货点负责人交谈！取货点负责人可以与供应商联系和沟通，并向他们申请送货！',
    p9_1:
      '我们的平台仅为买家提供取货点自行取货的服务。我们热衷于可持续性发展并建设社区。您的取货点负责人将同时收到社区的所有订单，让供应商能够更有效地重新分配库存，并通过批量交付减少对大气的碳排放。加入我们的可持续食品购物之旅吧!',
    privacy: '隐私',
    q1: '如何订购？',
    q2: '什么是团购？我该如何团购？',
    q3: '如何加入取货点？',
    q4: '我怎么知道取货时间？',
    q5: '我能取消订单吗',
    q6: '如果我的订单无法达到团购要求怎么办？',
    q7: '如果我收到不完整或有缺陷的产品，该怎么办？',
    q8: '为什么我的购物车中的一些产品“无法”进行结算？',
    q9: '你们送货上门吗？',
    refunds: '退款',
    related: '相关问题',
    title: '常见问题',
    top_qustions: '热门问题',
    treedots: 'TreeDots'
  },
  favorite: '收藏夹',
  favorites: '收藏',
  feedback: '反馈已发送',
  female: '女性',
  few_seconds_ago: '几秒钟前',
  file_saved: '文件已保存',
  file_upload_error: '超过了 50 MB 的最大文件大小',
  filter: '筛选',
  finance_label: '财务',
  find_company: '查找公司',
  find_out: '点击此处了解更多',
  find_products: '查找产品',
  first_name: '名字',
  first_name_cannot_be_empty: '名字不能为空',
  first_name_failed: '名字更新失败！',
  first_name_must_be_at_least_2_characters: '名字必须至少为 2 个字符',
  first_name_updated: '名字更新成功！',
  floor: '楼层',
  floorNo: '楼层号（可选）',
  fluid: 'Fluid',
  follow_us: '关注我们',
  following_company: '以下公司的报价可能会有所调整',
  for: '持续',
  for_delivery_on: '配送时间：',
  for_next_delivery: '获取次日达服务。',
  forgot_password: '忘记密码',
  forgot_password_1: '忘记了密码？',
  forgot_your_password: '忘记密码？',
  forward: '继续',
  fpx: 'FPX',
  free_sample: '免费样品',
  friends_join: '好友加入',
  from: '从',
  frozen_batang_steak: '冷冻巴当鱼排',
  fruits: '水果',
  fulfillment_status: '履行状态',
  future_date_error: '请选择当天或之前的日期',
  gender: '性别',
  gender_failed: '性别更新失败！',
  gender_updated: '性别更新成功！',
  generic: '通用',
  get_code: '没有收到代码？',
  get_help: '联系我们寻求帮助！',
  get_started: '开始吧！',
  glazing_desc: '冰衣指的是覆盖在冷冻海鲜表面的保护性水层',
  go_homepage: '前往主页',
  go_to_login: '前往登录',
  got_a_voucher: '有优惠券？',
  grabpay: 'GrabPay',
  grand_total: '总计',
  greeting: '优秀',
  group_buy: {
    how_group_buy_works: '团购的运作方式',
    slide_1: '加入附近的取货点！',
    slide_2: '与其他买家一起在取货点购物！',
    slide_3: '达到团购最低限额，一起领取订单商品吧！',
    slide_4: '与负责人和取货点社区聊天，以获取更新信息、共享食谱等！'
  },
  group_buy_available: '团购可用',
  group_by_item: '按商品分组',
  group_description: '群组描述',
  group_spend_total:
    '该供应商需要您的团购总额达<strong>{currencyCode}%{minimum_order}</strong>才能将订单商品运送到取货点。',
  gst_included: '适用情况下，包括消费税。',
  halal: '清真',
  halal_off: '如果此公司是清真企业，并且不希望看到非清真产品，请启用。',
  halal_on: '如果此公司不是清真企业，请禁用。将显示非清真产品。',
  halal_off_b2c: '如果此公司是清真企业，并且不希望看到非清真产品，请启用。',
  halal_on_b2c: '如果此公司不是清真企业，请禁用。将显示非清真产品。',
  halal_product: '清真产品',
  halal_products: '清真产品',
  halal_type: '清真类型',
  handle_not_connect_mess: ' 不是主要分支，无法连接。请联系您的办公室，请求与主公司建立连接。',
  handle_not_connect_mess_branch:
    '%{customer_name} 不是主要分支机构，无法连接。<br><br>请联系您的办公室，请求与主公司建立连接。',
  handle_not_connect_mess_inactive_customer:
    '%{customer_name} 已被 %{tenant_name} 停用，无法连接。<br><br>请联系您的办公室了解更多信息。',
  has_been_cancelled: '您的 {isOrderBooking} 已取消。',
  has_pickup_order: '我们的物流合作伙伴已取走您的 {isOrderBooking} 商品',
  have_not_set_pwd: '尚未设置密码？',
  health: '健康',
  hello: '您好',
  hello_new_string: '您好：',
  help: '帮助',
  hi: '嗨',
  history: '历史',
  hmm: '嗯…',
  home: '首页',
  host: '负责人',
  host_account: '负责人账户',
  hot_deals: '热销商品',
  hot_discounts: '热门折扣',
  hour: '小时',
  how_help: '我们能为您提供什么帮助？',
  i_have_question_about_order: '您好！关于我的订单 #{orderId}，我有一个问题。',
  i_would_like_to_purchase: '您好！我想为 {company_name} 购买 {sku_name}：',
  image: '图片',
  image_error_size: '图片尺寸过大。请上传小于 5 MB 的图片',
  image_error_type: '仅允许上传图像文件',
  image_lager_than_5mb: '该图像超过 5 MB 的大小限制！',
  image_success_transaction: '交易成功的图片',
  in: '于',
  in_stock: '有库存',
  inactive_supplier: '该供应商的订购尚未开始。您可以选择其他取货点进行购买。',
  incorrect_card_number: '添加新卡失败！您的卡号不正确。',
  incorrect_email_or_password_entered: '输入的电子邮箱或密码不正确',
  incorrect_phone_number: '输入的电话号码不正确',
  incorrect_role: '您不是顾客或销售人员',
  incorrect_verification_code: '输入的验证码不正确',
  info: '信息',
  input_amount: '输入金额',
  input_amount_error: '输入金额不能大于剩余积分',
  input_amount_error_2: '输入金额不能大于应付总金额',
  input_competitor_name: '输入竞争对手的名称',
  input_editional_info: '其他信息',
  input_feedback: '输入反馈 ',
  input_proposed: '请输入拟议价格或每周订单数量',
  input_reference_provided: '输入银行为此次支付交易提供的参考编号。',
  input_your_reasons: '输入您的原因',
  inquiries: '查询',
  instagram: 'Instagram',
  invalid_amount_negative: '金额无效。输入金额不能为负数',
  invalid_card_year: '添加新卡失败！您的卡片有效期无效。',
  invalid_phone_number: '电话号码无效',
  invalid_price: '无效价格',
  invite_friends: '邀请朋友',
  invite_friends_description: '邀请朋友注册，您和朋友下次购买时可享受 5 美元优惠',
  invite_friends_get_5: '邀请朋友即可获得 5 美元',
  invoice_cn: {
    all_file: '下载所有文件',
    bill_to: '账单寄送地点',
    cn: '信用票据',
    cn_amount: '信用票据金额',
    credit_note: '信用票据',
    download: '下载以查看原始发票',
    download_all: '下载所有文件',
    download_cn: '下载以查看原始信用票据',
    download_cn_pdf: '下载信用票据 (pdf)',
    download_file: '文件下载成功',
    download_file_fail: '文件下载失败',
    download_files: '文件下载成功',
    download_files_fail: '文件下载失败',
    download_pdf: '下载发票 (pdf)',
    grand_total: '总计',
    invoice: '发票',
    invoice_s: '发票',
    invoice_cn: '发票和信用票据',
    invoices: '发票',
    preview: '预览',
    share_credit_note: '共享贷方票据',
    share_invoice: '共享发票',
    sub_total: '小计',
    tax: '税费',
    view: '查看',
    view_document: '查看文档',
    view_invoice_cn: '查看发票和信用票据'
  },
  invoice_date: '发票日期：{invoiceDate}',
  invoice_list: '发票',
  invoice_total: '发票总额',
  invoices_amount: '发票金额',
  invoices_payment_history: '发票和付款记录',
  ios: 'ios',
  is_preparing_order: '正在准备您的 {isOrderBooking}',
  is_the_company_you_want: '上面是否列出了您要添加的公司？',
  item_unavailable_after: '商品不可购买',
  items: '商品',
  items_not_available: '部分商品无法提供',
  items_note: '商品备注',
  johor: '柔佛',
  join_now: '立即加入！',
  join_to_ordering: '加入此租户以开始订购',
  key_contact: {
    company_with_key_contact: '有主要联系人的公司',
    does_not_exits: '不存在',
    enter_first_name: '输入名字',
    enter_last_name: '输入姓氏',
    key_contact_information: '主要联系信息',
    select_position: '选择位置'
  },
  key_contact_label: '主要联系人',
  kg: 'KG',
  kindly_make_payment: '请立即付款 ',
  kitchen: '厨房',
  klang_valley: '巴生谷',
  language: '语言',
  language_preference: '语言偏好',
  last_name: '姓氏',
  last_name_cannot_be_empty: '姓氏不能为空',
  last_name_failed: '姓氏更新失败！',
  last_name_must_be_at_least_2_characters: '姓氏必须至少为 2 个字符',
  last_name_updated: '姓氏更新成功！',
  last_order: '上一个订单',
  last_quoted_price: '上次报价',
  last_remaming_item: '最后剩余 {remainingQuantity} ',
  last_remaming_weight_item: '最后剩余 {remainingQuantity} KG',
  later: '稍后',
  learn_more: '了解更多',
  leave: '离开',
  leave_channel_confirm_header: '离开当前聊天？',
  leave_channel_confirm_message: '您将无法看到此聊天中的对话。您随时可以通过加入此取货点返回。',
  leave_channel_toast_message: '您刚刚离开此频道',
  leave_community_channel_confirm_header: '离开社区聊天？',
  leave_community_channel_confirm_message: '确定离开当前社区聊天？',
  leave_private_channel_confirm_header: '离开私聊？',
  leave_private_channel_confirm_message: '确定离开当前私聊？',
  let_us_get_started: '开始吧！',
  let_us_know: '请告诉我们您更改订单的原因。',
  limit_price: '限价',
  linkedin: 'Linkedin',
  list_of_referrees_signed_up: '已注册 TreeDots 的推荐人列表',
  live_chat: '在线聊天',
  live_chat_support_only_available_in_android_and_ios_app: '实时聊天支持仅适用于安卓和 iOS 应用',
  load_failed: '加载失败',
  load_more: '加载更多',
  loading: '加载中…',
  loading_infinity_scroll: '加载更多数据...',
  location_permission_text1: '嗨，您好！输入您的邮政编码或允许位置访问以继续',
  location_permission_text2: '为了享受我们的地理定位服务，请允许 TreeDots 团购应用程序访问位置。',
  location_permission_text3: '您还可以通过以下方式允许在您的手机上访问位置信息：',
  location_permission_text4: '设置 > TreeDots 团购 > 位置 > 允许位置访问',
  location_permission_text5: '设置 >  位置 > 位置权限 > TreeDots 团购 > 允许',
  log_in: '登录',
  login: '登录',
  login_header_tagline: '欢迎来到 TreeDots，为您提供实惠优质的食品。',
  login_now: '立即登录',
  login_sticky: '登录以继续购物！',
  login_with_app_instead: '此手机号码已在我们的团购应用程序中注册。请尝试登录团购应用程序。',
  login_with_email: '使用电子邮箱登录',
  login_with_mobile: '使用手机号码登录',
  logout: '退出',
  logout_confirm: '确定要退出？',
  looking_to_browse1: '希望浏览我们的',
  looking_to_browse2: '供应商',
  looking_to_browse3: '？点击此处！',
  main_branch: '主分行',
  main_company: '主要公司',
  make_as_default_address: '设为默认地址',
  make_payment: '进行付款',
  malaysia: '马来西亚',
  male: '男性',
  manage_account: '管理账户',
  manage_companies: '管理公司',
  manage_customers: '管理公司',
  mandatory_field: '必填字段',
  map: {
    active_only: '仅限活动',
    cancel: '取消',
    collection_point_is_inactive: '取货点处于非活动状态',
    collection_point_near_me: '我附近的取货点',
    confirm: '确认',
    continue: '继续',
    delelect: '取消选择',
    dismiss: '忽略',
    enter_an_address_to_view_collection_points: '输入地址以查看取货点',
    enter_password: '输入密码',
    enter_your_address: '请输入您的地址',
    find_a_collection_point: '查找取货点',
    friends_only: '仅限朋友',
    get_your_pass: '向此取货点的负责人索取密码',
    halal: '清真',
    halal_only: '仅限清真食品',
    km: '公里',
    no_collection_point_nearby: '附近没有取货点',
    not_connected_to_cp: '您没有连接到任何取货点！',
    not_open_for_orders_right_now: '现在不接受订单',
    pass_incorrect: '密码不正确。请重试。',
    popup_message: '此取货点目前不接受订单。是否继续？',
    prefer_buyers_know:
      '此取货点的负责人更喜欢他们自己认识的买家。如果您认识该负责人，请在此处添加密码或选择公共取货点。',
    search_results: '搜索结果',
    search_this_area: '搜索此区域',
    show_inactive: '显示非活动状态',
    welcome_close: '关闭',
    welcome_join_now: '立即加入！',
    welcome_message: '加入我们的取货点，接收有关订单和配送情况的最新信息！',
    welcome_title: '欢迎来到 %{cp_name} 取货点'
  },
  markAsReadAll: '全部标记为已读',
  mark_all: '全部标记为已读',
  mark_all_as_read: '全部标记为已读',
  mark_as_read: '标记为已读',
  mark_as_received: '标记为已收到',
  mark_as_unread: '标记为已读',
  market_price: '竞争对手的价格',
  master_card_debit: '万事达卡（借记卡）',
  max: '最大值',
  max_file_size: '最大文件大小为 50 mb',
  maximum_quantity_exceeded: '已超过最大数量。',
  meals: '餐食',
  meat: '肉类',
  medium_color: '中等',
  melaka: '马六甲',
  member: '成员',
  members: '成员',
  menu: '菜单',
  menu_items: {
    category: '类别',
    home: '首页',
    notifications: '通知'
  },
  merchandise_subtotal: '商品小计',
  merchant: '商户',
  message_cart_empty: '您的购物车是空的。',
  message_forwaded: '已转发消息',
  message_join_to_tenant: '您已请求加入此租户',
  method_send_invoice: '请选择此发票的发送方式。',
  min: '分钟',
  minimum_5_char: '请输入至少 5 个字符！',
  minimum_order_malaysia: '最低订单价值为 3.00 令吉',
  minimum_order_singapore: '对于非电子钱包付款，最低订单金额为 0.50 新元',
  missing_payment_proof: '请上传付款证明',
  mission:
    '展望一个没有食物损失的世界，我们的目标是成为该地区现代食品供应链的催化剂和高效的食品分销渠道，使实现可持续发展更容易，更容易为所有人所接受。我们的愿景是，经过精心饲养和播种的食物将不会被浪费。',
  mobile_already_registered: '此手机号码已注册',
  mobile_already_registered_please_login:
    '该手机号码已被注册。请使用注册的电话号码登录或使用新电话号码创建。',
  mobile_not_registered: '您尚未注册，请注册一个帐户以开始使用！',
  mobile_number: '手机号码',
  mobile_phone: '手机号码',
  more: '更多',
  more_credit_term: '供应商操作面板上提供了更多信用期限选项',
  more_products: '更多产品',
  most_popular: '最受欢迎',
  most_purchased: '购买最多的',
  msg_delete: '消息已删除',
  mute: '静音',
  my_account: '我的账户',
  my_cards: '我的卡片',
  my_company: '我的公司',
  my_custom_class: '我的-自定义-类别',
  my_invoices: '我的发票',
  my_orders: '我的订单',
  name: '名称',
  name_az: '名称 A-Z',
  name_za: '名称 Z-A',
  nets_bank_card: 'NETS 银行卡',
  nets_payment_error: 'NETS支付错误',
  nevermind_keep_my_account: '好吧，保留我的帐户',
  new_assigned_company: '一些 {connectedPersons} 公司将您指定为主要联系人。请查看并予以接受或拒绝。',
  new_email: '新电子邮箱',
  new_onboarding_1: '为您的企业提供优质、优惠的食品配料',
  new_onboarding_2: '在一个简单的应用程序中管理多个实体',
  new_order_placed: '下了新订单！',
  new_password: '新密码',
  new_phone: '新电话号码',
  new_request: '新建请求',
  new_request_buyer: '{applicant} 希望指派您担任 {customerName} 的 {position} 负责人',
  new_request_info: '{supplierName} 的 {applicant} 希望指派您担任 {customerName} 的 {position} 负责人',
  new_request_info1: '从',
  new_request_info2: '希望指派您担任',
  new_request_info3: '……人员',
  newcustomer: {
    active: '活动',
    add_brand: '添加分行',
    add_debit: '添加借记卡/信用卡',
    add_nets: '添加 NETS 银行卡',
    add_person_in_charge: '添加负责人',
    address: '地址',
    close_time: '结束时间',
    company_name: '公司名称',
    days: '天',
    default_delivery_time: '默认配送时间',
    delivery_details: '配送详情',
    edit_business_information: '编辑商业信息',
    edit_delivery_time: '编辑配送时间',
    edit_image: '编辑图像',
    edit_payment_card: '编辑付款卡',
    edit_person_in_charge: '编辑负责人',
    enter_delivery_interval: '配送说明',
    language: '语言',
    main_brand: '主分行',
    notifications: '所发送通知将采用这种语言。',
    open_time: '开始时间',
    payment_card_details: '付款卡详细信息',
    person_in_charge: '负责人',
    profile: '个人资料',
    same_as_parent_company: '与母公司相同',
    set_as_default: '设为默认',
    set_default_time: '设置默认时间',
    set_delivery_days: '设置配送天数',
    set_payment_card_details: '设置支付卡详细信息',
    update_address: '更新地址',
    update_person_in_charge: '更新负责人',
    upload_company_logo: '上传公司标志',
    upload_image: '上传图片',
    assign_as_soa_receiver: 'Assign as SOA receiver'
  },
  newest: '最新',
  next: '下一步',
  next_day: '“次日达”选项不可用',
  no: '否',
  noData: '暂无数据',
  no_alert_confirm: '不，创建新订单。',
  no_cash_off:
    '如果您要求该公司在交货前或交货时付款以便接收商品，请启用此选项。如果不符合此交货条件，供应商将有权收回商品。',
  no_cash_on: '如果您不要求该公司在交货前或交货时付款以履行订单，请禁用。',
  no_cc: '尚未添加信用卡',
  no_chat_text1: '抱歉，未找到任何结果。',
  no_chat_text2: '我们找不到您正在搜索的关键词。',
  no_chat_text3: '请尝试其他关键词。',
  no_continue_adding_new_company: '否，继续添加新公司',
  no_credit_card_selected: '请选择付款方式',
  no_delivery_date: '很抱歉！您的订单没有可用交货日期！',
  no_expiry_date: '无失效日',
  no_guaranted_delivery: '不保证交付',
  no_guaranted_message: '您选择的交付时间已过截止时间。您是否检查过该订单能否继续履行',
  no_invoices: '暂未收到发票！',
  no_longer_available: '不再供应',
  no_name: '无名称',
  no_new_unread_notif: '没有新的未读通知',
  no_notification: '无通知',
  no_notification_text1: '您的通知将在此处显示！',
  no_notification_text2: '我们将使用此空间提醒您',
  no_notification_text3: '订单和促销！:)',
  no_preview_docs: '无法预览',
  no_product_found: '未找到产品',
  no_product_in_category: '这里还没有产品，',
  no_quantity: '该供应商无法供应足够的商品数量。',
  no_referred_text: '还未推荐朋友',
  no_results_found: '未找到结果',
  no_search_result: '我们找不到任何符合您搜索条件的产品。',
  no_search_text1: '我们找不到任何产品',
  no_search_text2: '匹配您的搜索结果！',
  no_subcategory1: '此类别目前没有商品。',
  no_subcategory2: '下次再来！',
  no_supcategory: '该供应商目前没有商品。',
  no_supplier_text1: '我们致力于将供应商带到您的城市。我们希望您很快就会喜欢上在我们的平台购物！',
  no_supplier_text2: '不确定下一步该怎么做？',
  no_supplier_text3: '请',
  no_supplier_text4: '联系我们',
  no_transaction_made: '未进行交易。',
  no_update_available: '无可用更新',
  no_vouchers_text: '未找到优惠券',
  non_halal_product: '非清真',
  non_pooling: '非共同分配',
  not_allow: '不允许',
  not_found_order: '抱歉，我们找不到您要查找的订单！',
  not_found_payment_history_desc: '前往主页浏览一些产品！',
  not_found_payment_history_title: '您没有付款记录',
  not_provided_address: '未提供地址',
  not_sure_avail_credit: '不确定什么是可用积分？',
  not_sure_credit_desc: '如果您对可用积分及其使用方式还有其他疑问，请通过以下方式联系我们',
  note: '备注',
  note_only: '注',
  note_to_driver: '司机须知',
  note_for_self_pickup: '自提备注',
  notes: '备注（可选）',
  nothing_here_yet: '暂无相关消息！请稍后再查看',
  notif_alert_desc: '通知可能包括警报、声音和图标提示。这些都可以在“设置”中配置。',
  notif_alert_label: '“TreeDots for Merchants”希望向您发送通知',
  notif_no_spree_open: '此取货点目前不开放订购。请稍后再来或选择其他取货点！',
  notification: '通知',
  notifications: '通知',
  off: '关闭',
  on_hold: '暂停',
  on_registration: '注册时',
  onboarding1_text: '排名第一的团购平台，与朋友和邻居一起享受巨大优惠！',
  onboarding1_title: '为您的企业提供优质、优惠的食品配料',
  onboarding2_text: '我们与值得信赖的供应商合作，这些供应商为您喜爱的市场和餐厅提供食品。',
  onboarding2_title_1: '优质食品。',
  onboarding2_title_2: '在一个简单的应用程序中管理多个实体',
  onboarding3_text: '购买可持续食品，并到您的首选取货点领取！',
  onboarding3_title: '发现',
  onboarding4_text: '与取货点负责人和其他买家聊天！',
  onboarding4_title: '加入一个不断发展的社区！',
  onboarding_button: '购买食品',
  one_or_more_company:
    '在我们的数据库中，有一家或多家公司的邮政编码与您输入的邮政编码相同。如果您要添加的公司已经存在，请直接连接。',
  one_or_more_company1:
    '在我们的数据库中，有一家或多家公司的电话号码与您输入的电话号码相同。如果您要添加的公司已经存在，请直接连接。',
  only_allowed_symbol: '仅允许以下符号',
  open_alert_reject: '是否要拒绝预订',
  open_document: '打开文档',
  operation_label: '操作',
  optional: '（可选）',
  or_sign_in_with: '或通过以下方式登录',
  or_use: '或使用更多',
  order: {
    accept_rejection: '接受拒绝',
    accept_rejection_confirm_description: '接受拒绝即表示您同意结束此争议。您将不会收到退款。',
    accept_rejection_confirm_title: '确定接受拒绝？',
    action_not_be_reversed: '此操作无法撤消',
    allow_refund_default: '始终退款至 TreeDots Pay',
    amount_refunded: '退款金额：',
    appeal_description:
      '由于拒绝提议，此问题现在将上报到 TreeDots 调解中心。大约需要 12-24 小时审查。审核完成后，您将立即收到最新信息。',
    appeal_reason_input_placeholder: '请详细说明您认为出现此问题的原因，以便我们快速进行调查。',
    appeal_submitted: '已提交申诉',
    appeal_submitted_description:
      '此问题现已上报到 TreeDots 解决中心。审核大约需要 1 天时间。审核完成后，您将立即收到更新信息。',
    appeal_to_treedots: '向 TreeDots 申诉',
    attachment: '附件',
    back: '返回',
    browse: '浏览',
    buy_again: '再次购买',
    cancel: '取消',
    cancel_Item_modal: '取消商品',
    cancel_item: '取消商品？',
    cancel_modal_text: '您确定要取消此商品吗？',
    cancel_order_confirm: '您确定要取消此订单吗？',
    cancel_order_succeed: '取消成功',
    cancel_refund_default: '稍后',
    cancelled: '已取消',
    cashback: '返现',
    change_company: '更改公司',
    chart_with_hub: '与负责人聊天',
    chat_not_ready: '此社区聊天尚未准备好！',
    collected: '已取货',
    collection_date: '取货日期：',
    collection_point: '取货点',
    completed: '已完成',
    date_of_request: '申请日期',
    date_resolved: '解决日期',
    dispute: '争议',
    dispute_attach_description:
      '为您的问题附上支持性证据。您可以附上照片（JPG、JPEG 和 PNG）或视频（MP4），不超过 5 个文件 和 10MB',
    dispute_attach_photo_video: '附上照片或视频',
    dispute_detail: '争议详情',
    dispute_history: '争议历史记录',
    dispute_id: '争议编号',
    dispute_review_description:
      '此问题现已上报到 TreeDots 调解中心。审核大约需要 1 天时间。审核完成后，您将立即收到更新信息。',
    draft: '草稿',
    estimated: '预计',
    estimated_collection_date: '预计取货日期',
    group_buy_progress: '团购进度',
    have_been_rewarded: '您已获得现金返还奖励！',
    have_no_cancelled: '您没有“已取消”的商品。',
    have_no_collected: '您没有“已取货”的商品。',
    have_no_overview: '您没有“概览”的商品。',
    have_no_preparing: '您没有“正在准备”的商品。',
    have_no_to_collect: '您没有“待取货”的商品。',
    manage_account: '管理帐户',
    my_account: '我的帐户',
    my_orders: '我的订单',
    need_help: '需要帮助？',
    ongoing: '正在进行',
    opening_chat: '正在打开聊天...',
    order_No: '订单号',
    order_amount: '订单金额：',
    order_id: '订单编号',
    order_title: '订单',
    order_total: '订单总计：',
    our_food: '我们的食品，开始向我们订购!',
    overview: '概览',
    package_no: '包裹编号',
    polling_item: '共同分配商品',
    preparing: '正在准备',
    processed: '已处理',
    qty: '数量：',
    reason_for_appeal: '申诉理由',
    refund: '退款：',
    refund_modal_bold_text: '账户 > TreeDots Pay',
    refund_modal_header: '希望下次能立即收到退款？',
    refund_modal_text: '选择 TreeDots Pay 电子钱包为退款的主要方式。您随时可以进行更改',
    refund_total: '退款金额：',
    refunded: '已退款',
    rejoin_chat_message: '您之前已经离开了此聊天。想再次加入吗？',
    rejoin_chat_no: '不，谢谢',
    rejoin_chat_title: '重新加入此聊天？',
    rejoin_chat_yes: '加入聊天',
    resolution: '解决方案',
    resolve_dispute: '解决问题',
    resolve_dispute_description: '此问题将在 24 小时内自动解决。',
    submit_appeal: '提交申诉',
    supplier_rejection_description1: '供应商拒绝了退款请求 ',
    supplier_rejection_description2: ' 对于产品。请查看争议历史记录以了解详情。',
    supplier_rejection_reason: '供应商拒绝的原因',
    text_item_required:
      '此商品需要确认达到最低限额才能取货。目前正在等待您所在社区的其他买家购买此商品！如果您不想等待，可以取消商品订单（不会向您收取此商品的费用）。',
    text_missing_items: '为什么我购买的商品少了？',
    text_pooling_items:
      '共同分配商品需要确认取货的最低限额。您订购的商品数量超出该商品池的最低要求。但别担心！您的待处理商品很快可以在下一个买家池中确认！',
    to_collect: '取货',
    total_paid: '已付总额：',
    track_issue: '跟进问题',
    view_detail: '查看详情',
    view_history: '查看历史',
    volume_deal: '批量交易',
    voucher_savings: '优惠券节省：',
    want_to_cancel_issue: '是否确定要取消问题吗？',
    weight_error_out_of_range: '订单的最大重量不得超过100,000,000,000,000 千克。请减少订单重量。',
    you_will_receive: '您将在 7-10 个工作日内收到退款。'
  },
  orderB2b: {
    acceptingRemedy: '接受商品，无任何补救措施',
    accountNumber: '帐号',
    addPICMessage: '在提交订单之前，请向商家账户添加 PIC',
    attention: '注意',
    backToCart: '返回到购物车',
    backToHome: '返回主页',
    billTo: '账单寄送地点',
    cancelConfirmButton: '确认',
    cancelPaymentHint: '您的付款和订单将被取消，并且不会向您收费。',
    cancelPaymentTitle: '取消付款和订单？',
    cancelPaymentTransaction: '取消付款和订单',
    cancelStayButton: '取消',
    companies: '公司',
    created: '已创建',
    deliverTo: '配送地址',
    delivery: '配送',
    deliveryInstruction: '配送说明',
    delivery_address: '配送地址',
    deplayDelivery: '配送延迟',
    detail: '详情',
    discount: '折扣',
    downloadInvoice: '下载发票',
    dueDate: '截止日期',
    filter: '筛选',
    fluidWarning: 'Fluid融资的客户无法使用其他付款方式',
    from: '发件人：',
    fulfillmentDateFrom: '履行日期自',
    fulfillmentDateTo: '履行日期至',
    goodsSpoild: '商品变质',
    grandTotal: '总计',
    gst: '消费税',
    invoice: '发票',
    invoiceDate: '发票日期',
    invoiceNumber: '发票号码',
    item: '商品',
    items: '商品',
    missingItem: '缺少商品',
    order: '订单',
    orderItemDetail: '订单商品详情',
    orderNow: '立即订购',
    orderNumber: '订单号码',
    orders: '订单',
    payment: '付款方式',
    paymentExpireIn: '剩余有效期：',
    paymentFail: '付款失败',
    paymentFailMessage: '抱歉！因为无法收到您的付款，我们已取消您的订单',
    paymentInstruction: '付款说明',
    paymentMethod: '付款方式',
    paymentPending: '待付款',
    paymentPendingHeadline: '此时完成付款：',
    paymentPendingMessage: '正在等待支付合作伙伴的确认，然后才能确认您的订单！',
    paymentStatus: '付款状态',
    paymentStatusUpdated: '付款状态已更新！',
    paymentStatusUpdatedHint: '请关闭此页面继续',
    paymentSuccess: '付款成功',
    paymentSuccessMessage: '已成功下订单',
    paymentTime: '支付时间',
    paymentTransactionExpired: '付款已过期！',
    paynowInstruction: '您的PayNow二维码有效期为1小时，请在有效期内付款！',
    pendingPayment: '待处理的付款',
    pendingSupplierAcceptance: '等待供应商接受',
    placeOrder: '下订单',
    poNumber: '订单号',
    preOrder: '预购',
    price: '价格',
    purchase: '采购',
    qty: '数量',
    recipient: '收件人',
    remark: '备注',
    repeat: '重复',
    repeatOrder: '重复订购',
    reportProblem: '报告问题',
    requestingCreditNote: '申请退货凭证',
    requestingExchange: '申请 1:1 换货',
    requestingItem: '申请取走发错的商品',
    reset: '重置',
    selectDate: '选择结束日期必须晚于开始日期',
    selectDeliveryDate: '选择配送日期',
    selectProblem: '选择问题的原因',
    selectRemedy: '选择的补救措施',
    sendInvoice: '发送发票',
    separateInvoice: '单独发票',
    showInvoice: '显示发票',
    sku: 'SKU',
    status: '状态',
    subTotal: '小计',
    submitPreOrder: '提交预订',
    tax: '税费',
    taxInvoice: '税务发票',
    taxRegistrationNo: '税务登记号',
    to: '收件人',
    total: '总计',
    totalAmount: '总计',
    totalPrice: '总价',
    unablePlaceBooking: '无法进行预订',
    unitPrice: '单价',
    updateOrderSuccess: '更新成功',
    viewDispute: '查看争议',
    viewDisputeStatus: '查看争议状态',
    viewOrderDetails: '查看订单详情',
    viewQRCode: '查看二维码',
    weight: '重量',
    deliveryFeeApplied: '由于最低订单金额 {orderValue} 未满足，送货费 {deliveryFee} 将被收取。',
    deliveryFeeUpdated: '由于已满足最低订单金额，送货费已更新为 {deliveryFee}。',
    deliveryFeeRemoved:
      '如果合并订单，订单 #{mergeTargetOrder} 的最低订单金额 {minOrderValue} 将被满足，送货费将被免除。'
  },
  order_added: '订单已添加！',
  order_amount: '订单金额',
  order_by_weight: '按重量订购',
  order_cancel_by_supplier: '供应商已取消 {isOrderBooking}',
  order_cancelled: '{isOrderBooking} 已取消',
  order_completed: '订单已完成',
  order_created: '我们已收到您的订单！',
  order_delivery_instruction: '配送说明',
  order_failed: '订单失败',
  order_has_been_amended: '您的订单已被修改',
  order_has_been_completed: '您的订单已完成！',
  order_hold: '订单暂停',
  order_is_on_hold: '您的订单处于暂停状态',
  order_is_pending_acceptance: '正在等待供应商接受订单。',
  order_item: '订单商品：',
  order_lowcase: '订单',
  order_merged: '订单合并 - 您的商品已添加到订单中',
  order_no_longer_blocked: '订单已取消锁定，请刷新页面以查看最新状态。',
  order_now: '立即订购',
  order_of_measure: '测量顺序',
  order_on_hold: '订单处于暂停状态',
  order_processed: '{isOrderBooking} 已处理',
  order_processing: '{isOrderBooking} 已下单',
  order_processing_status: '{isOrderBooking} 正在处理',
  order_received_date: '收件日期',
  order_remark: '订单备注',
  order_status: '订单状态',
  order_submitted: '已提交订单',
  order_total: '订单总计',
  order_update_detail: '订单更新详情',
  ordering: '订购和取货',
  ordering_collection: {
    find_out_more: '此处了解有关订购和取货的更多信息！',
    p1: '当您在我们平台上购物时，将加入到团购中。',
    p2: '浏览各种优质产品，一起享受超值优惠！',
    p3: '在您的首选取货点结算食品！ ',
    p4: '与其他买家一起组成最低团购限额并领取订单商品！',
    p5: '如果您对订单有任何疑问，请向取货点负责人咨询！',
    take_a_tour: '开始浏览',
    take_step: '查看分步指南，了解团购方式'
  },
  orders: '订单',
  orders_and_invoices: '订单和发票',
  orders_and_invoices_notification: '订单和发票通知',
  orders_temporarily_closed: '暂时关闭的订单',
  originalPrice: '原价',
  other: '其他',
  other_info_competitor: '例如，购买日期、促销价格',
  other_info_error: '超过了 255 个字符的最大数量限制',
  other_information: '其他信息（可选）',
  other_methods: '其他方式',
  other_payment_method: '其他付款',
  others: '其他',
  our_mission: '我们的使命和愿景',
  our_research: {
    content_1: '我们发现，2017 年，单单是新加坡就浪费了超过 81 万吨的食物。',
    content_2:
      '更糟糕的是，向垃圾填埋场倾倒如此多的食物会产生大量的甲烷气体，对我们地球的健康会造成更大的影响。',
    title: '我们已经做了研究'
  },
  our_solution: {
    content_1:
      '2019 年年中，我们构思了应用程序的第一个版本。我们打造了一个在线平台，允许供应商通过我们的订单管理和物流服务重新分配过多的食品库存。',
    content_2:
      '通过低平台费用，我们向供应商展示了另一种具有成本效益的方式来处理未售出的库存并与顾客建立联系。',
    title: '我们的解决方案'
  },
  our_team_will_reply_to_your_inquires_in_2_3_working_days: '我们的团队将在2-3个工作日内回复您的询问。',
  out_of_max_quantity: '最大数量为 {quantity}！',
  out_of_max_weight: '最大重量为 {weight} KG！',
  out_of_stock: '缺货',
  overdue: '已过期',
  overdue_copy: '已过期',
  overpayment: '多付费用',
  overview: '概览',
  pack_of: '一包 ',
  packaging_size: '包装尺寸',
  page_taking_longer: '此页面加载时间过长。您可能需要检查您的互联网连接',
  paid: '已支付',
  paid_amount: '已付金额',
  paid_copy: '已支付',
  paid_payment: '失败',
  parent_address: '父母的地址',
  partially_paid: '部分付费',
  password: '密码',
  password_cannot_be_empty: '密码不能为空',
  password_failed: '密码更新失败！',
  password_must_be_at_least_6_characters: '密码必须至少为 6 个字符',
  password_successfully_set: '密码设置成功！',
  password_updated: '密码更新成功!',
  past_cut_off_time: '已过截止时间',
  past_date_error: '请选择当天或之后的日期',
  pay: '付款',
  pay_invoices: '支付发票',
  pay_now: '立即付款',
  payment: '付款方式',
  payment_amount_warning: '付款总额必须超过',
  payment_creation_failed: '付款创建失败',
  payment_date: '付款日期',
  payment_details: '付款详情',
  payment_duedate: '到期应付款：',
  payment_failed: '付款失败',
  payment_history: '支付历史',
  payment_method: '付款方式',
  payment_option: '付款选项',
  payment_option_page: {
    other_payment_method: '其他付款方式',
    treedots_balance_modal_page: {
      amount: '金额',
      change_payment_method: '更改付款方式',
      oops: '糟糕，您的余额不足',
      to_complete: '为了完成您的交易，请为您的 TreeDots 钱包充值或选择其他付款方式',
      top_up_treedots: 'TreeDots 钱包充值'
    },
    treedots_pay: 'TreeDots Pay'
  },
  payment_overdue_invoice: '为 {userName} 的逾期发票付款',
  payment_pending: '待付款',
  payment_pending_info: '我们目前正在处理您的付款，更新后的付款状态需要一些时间才能反映在系统上',
  payment_proof: '付款证明',
  payment_status: {
    cancelled: '已取消',
    paid: '已支付',
    pending_approval: '等待批准',
    pending_validation: '等待验证',
    rejected: '已拒绝'
  },
  payment_status_changed: '付款状态已发生变化！',
  payment_success: '付款成功',
  payment_summary: '付款摘要',
  payment_term: '付款条件：',
  payment_time: '付款时间',
  payment_value_date: '付款起息日期',
  paynow: 'PayNow',
  penang: '槟城',
  pending: '待处理',
  pending_approve: '尚待批准',
  pending_payment: '待付款',
  pending_payment_approval: '待付款批准',
  pending_validation: '等待验证',
  pending_verification: '等待验证',
  per: '单价',
  per_unit: '[单位]',
  per_unit_price: '单价',
  perak: '霹雳州',
  person: '人员',
  person_type: '人员类型',
  pet: '宠物',
  phone: '电话号码',
  phone_alt_img: '电话',
  phone_failed: '电话号码更新失败！',
  phone_logo_url: 'https://cdn2.iconfinder.com/data/icons/circle-icons-1/64/phone-256.png',
  phone_number: '电话号码',
  phone_number_cannot_be_empty: '电话号码不能为空',
  phone_share_link: '电话：+65 3138 5464',
  phone_updated: '电话号码更新成功！',
  phone_verified: '电话号码验证成功！',
  photo: '照片',
  place_booking: '进行预订',
  place_order: '下单',
  please_check_your_email_password: '请检查您的电子邮件和密码',
  please_enter_valid_mobile_number: '请输入有效的手机号码',
  please_enter_valid_username_password: '请输入有效的用户名和密码',
  please_enter_verification_code:
    '请输入发送到您的手机号码中的验证码 <br><strong>{countryCode} {number}</strong>',
  please_enter_email_verification_code: '請輸入發送到您信箱的驗證碼<br><strong>{email}</strong>',
  please_make_payment_or_contact_admin: '请付款或联系您的管理员以获取更多信息！',
  please_make_payment_or_contact_supplier: '请付款或联系您的供应商以获取更多信息！',
  please_make_sure_connected: '请确保您已连接到 Internet，然后重试',
  please_provide_your_password: '请提供您的密码',
  please_provide_your_username: '请提供您的用户名',
  please_select_cuisine: '请选择与您的企业相关的菜肴。您可以选择多种菜肴！',
  please_select_language: '请选择语言。',
  please_try_again: '请重试',
  please_wait: '请稍候',
  please_select_your_country: '请选择您的国家',
  po_number: '订单号',
  po_number_optional: '订单号（可选）',
  pool_item: '共同分配商品',
  pool_together: '共同分配',
  pooling: '共同分配',
  pooling_item: '共同分配商品',
  position: '位置',
  postal_code: '邮政编码',
  postal_code_cannot_be_empty: '邮编不能为空',
  postal_code_not_found: '未找到含此邮政编码的公司。',
  postal_code_placeholder: '例如 456123',
  previous: '上一页',
  price: '价格',
  priceUpdatedSuccessfully: '价格更新成功',
  price_cannot_be_empty: '价格不能为空',
  price_change_log: '价格变化记录',
  price_changes: '很抱歉，价格已更改',
  price_is_quoted: '已报价',
  price_log_info: '受价格更新影响的公司',
  price_normal: '价格',
  price_per: '价格 /',
  price_update: '价格已更新',
  price_updated: '价格已更新',
  privacy: '隐私政策',
  privacy_policy: {
    change_privacy: '7.隐私政策的变更',
    children: '3.儿童隐私',
    collection: '2.个人数据的收集',
    cookies: '6.Cookies 和移动技术',
    effective: '自 2020 年 5 月 14 日起生效。',
    intro: '1. 简介',
    p1:
      '本隐私政策适用于 TreeDots Enterprise（私人有限公司）、TreeDots International（私人有限公司）（统称为 “TreeDots”、“我们”、“我们的”）提供的产品、服务、网站、移动应用程序和/或开展的业务，并解释了我们如何处理个人数据并遵守新加坡《个人数据保护法》（“PDPA”）的要求。TreeDots非常重视顾客及网站（如 www.thetreedots.com）和 TreDots 移动应用程序用户的个人数据的安全性和隐私性（此类网站和移动应用程序可以统称为“网站”；我们的移动应用程序可能被称为“应用程序”）。',
    p1_1:
      '1.1 使用我们的服务、网站或应用程序，即表示您承认并同意我们根据本政策收集和使用您的个人数据。我们不会通过本隐私政策所述以外的方式使用或共享您的个人数据。',
    p1_2:
      '2.1 本隐私政策将帮助您了解我们如何收集、使用、披露和/或处理您提供给我们或我们所持有与您相关的个人数据，以及在向我们提供您的任何个人数据之前协助您做出明智的决定。“个人数据”一词是指与作为可识别个人的您相关的身份信息，根据《个人数据保护法》的定义，该词指可从数据中识别的个人信息或从组织已经或可能有权访问的数据和其他信息中获取的个人信息（“个人数据”），无论真实与否。这可能包括您的姓名、地址、电话号码、电子邮件地址、出生日期、性别、信用卡详细信息、设备 ID、操作系统或版本、有关您的购买或偏好的信息，以及我们收集的已识别出或可识别您的身份的任何其他信息。',
    p1_3: '本隐私政策受新加坡法律约束。',
    p2_1: '2.1 我们可能通过各种方式收集您的个人数据，包括但不限于您进行的以下操作：',
    p2_1a: '• 通过我们的网站或应用程序提供个人数据，以用于注册和创建帐户；',
    p2_1b: '• 访问本网站或访问或下载应用程序；',
    p2_1c: '• 同意网站或应用程序访问您的国家/地区；',
    p2_1d: '• 参与促销活动或使用其他应用程序功能；',
    p2_1e: '• 处理付款或信用交易；',
    p2_1f: '- 要求提供产品或服务信息或接收任何营销、促销或其他类型的通信；',
    p2_1g:
      '• 要求我们提供商品和/或服务和/或与我们的网站或应用程序相关或与相连的任何其他附带商业目的 • 通过我们的应用程序进行购买；',
    p2_1h: '• 通过我们的顾客成功部门进行查询和/或；',
    p2_1i: '• 与我们的销售人员或与我们互动。',
    p2_2:
      '2.2 在使用我们的服务、网站或应用程序时，或请求我们的协助时，我们可能会自动收集或要求您向我们提供您的个人身份信息，这些信息可能包括但不限于：',
    p2_2a: '• 姓名；',
    p2_2b: '• 电子邮件地址；',
    p2_2c: '• 电话号码；',
    p2_2d: '• 付款信息；',
    p2_2e: '• 位置数据；',
    p2_2f: '• 媒体文件；',
    p2_2g: '• 通信历史数据；',
    p2_2h:
      '• 使用和交易数据，包括有关您的搜索、订单、您使用网站或应用程序时接触到的广告和内容以及与您相关的其他产品和服务的详细信息。',
    p2_2i:
      '您没有义务提供我们要求的任何个人数据。但是，根据情况，如果需要收集、使用或披露您的个人数据，我们可能无法向您提供某些产品和服务，或无法与您进行交易。',
    p3_1:
      '3.1 我们不会也不打算收集、使用、分享任何未满 18 岁的的人的个人数据或通过我们的平台直接与我们知道未满 18 岁的人进行交易。如果您未满 18 岁，您应该只在父母或监护人的干预下使用我们的网站、应用程序或服务，并且不应该向我们提交任何个人数据。您向我们提供任何个人资料，即表示您已年满 18 岁。',
    p4_1:
      '4.1 在开展我们的业务时，TreeDots 可能/将需要出于各种目的向我们的第三方服务提供商，代理商和/或我们的关联公司或相关公司和/或其他第三方披露或共享您的个人数据，同样地，此类第三方将出于这些目的处理您的个人数据。',
    p4_2:
      '4.2 我们的第三方服务提供商可能会代表我们或以其他方式收集和处理您的个人数据，用于以下一项或多项目的，包括但不限于提供分析、管理、广告、物流和信息技术服务的目的。',
    p5_1:
      '5.1 我们将努力采取一切合理措施，确保您的个人资料安全保密，并采取适当的技术和组织措施，防止非法或意外破坏、意外损失、未经授权的披露或访问或其他非法形式的处理。用户个人数据储存在安全网络中，只有少数对此类系统具有特殊访问权限的员工才能访问。',
    p5_2:
      '5.2 我们实施合理的安全安排，以确保您的个人数据得到充分的保护和保障。然而，当我们完全无法控制的因素导致第三方未经授权使用您的个人资料时，我们不承担任何责任。',
    p6_1:
      '6.1 除上述内容外，我们可能会使用以下技术（详见下文）来自动收集有关您在应用程序或网站上的活动的信息（视情况而定）：',
    p6_1a: '• Cookie • 网络信标 • 分析标签 • Web 服务器日志 • 地理定位技术',
    p6_2:
      '6.2 Cookies。对于本网站的用户，请注意，TreeDots 可能会在您的电脑或移动设备中存入“cookies”，以便识别您的身份。Cookies 是在浏览过程中由服务器计算机发送的小型数据文本文件。Cookies 通常存储在电脑硬盘上，由网站用来模拟与网站的连续连接。我们已经采取了安全措施，以防止未经授权访问访问者的数据。然而，访客理解 TreeDots 无法控制包括互联网在内的电信设施的数据传输。因此，在法律允许的范围内，对于非因TreeDots 的过错而造成的任何安全漏洞或未经授权披露或使用互联网上的任何此类数据，TreeDots 将不承担责任。并非所有的 cookies 都会收集个人数据，您可以将您的浏览器设置为拒绝接受 cookies。但是，这可能意味着您可能无法充分利用本网站的服务或功能。',
    p6_3:
      '6.3 网络信标。网络信标是网页或电子邮件中的小型图形图像，可用于记录用户点击的网页和广告，或跟踪电子邮件营销活动的表现。',
    p6_4:
      '6.4 分析标签。我们使用分析标签来分析客户喜欢做什么以及我们的功能和广告的有效性。我们可能会结合使用通过分析标签或跟踪链接收集的信息和您的个人数据。',
    p6_5:
      '6.5 网络服务器日志。网络服务器日志是由向您的浏览器提供您所要求的网页的移动设备或计算机所创建的活动记录。例如，网络服务器日志可能会记录您输入的搜索词或显示网页前所点击的链接，和关于您的浏览器的信息，如您的 IP 地址和服务器在您的浏览器上设置的 cookies。',
    p6_6:
      '6.6 地理定位技术。地理定位技术是指允许我们确定您的国家/地区信息的技术。我们可能会要求您手动提供国家/地区信息（如您的邮政编码），或让您的移动设备向我们发送您的国家/地区信息。',
    p7_1:
      '7.1 TreeDots 保留随时更改其隐私政策的权利，并将在我们的平台上通知您有关政策的更新。在适用法律允许的最大范围内，您继续使用我们的服务、网站或应用程序，包括下任何订单，即表示您确认并接受对本隐私政策所做的更改。',
    p7_2: '7.2 本政策适用于使用我们的服务、网站或应用程序的买家和卖家，除非另有明确说明。',
    protecting: '5.保护您的个人数据',
    sharing: '4.个人信息的共享和披露',
    title: 'TreeDots 隐私政策'
  },
  private: '隐私',
  processing: '正在处理',
  product: '产品',
  productInfo: '产品信息',
  product_availability: '产品供货情况',
  product_category: '产品分类',
  product_detail: {
    achieve_min: '一起达成最低购买金额',
    add_to_cart: '加入购物车',
    add_to_cart_failed: '添加到购物车失败',
    add_to_cart_success: '成功添加到购物车',
    almost_stock: '差点缺货了！',
    buy_again: '再次购买',
    buy_together: '一起购买',
    buy_with_others: '与他人一起购买',
    can_not_view: '无法查看',
    can_not_view_hint: '商家用户无法查看和提交订单。',
    country_of_origin: '原产地',
    country_origin_info: 'It’s where the product imported from',
    discounted_price: '折扣价',
    explore_other_product: '探索其他产品',
    halal: '清真',
    how_group_buy_works: '团购的运作方式',
    item_weight: '商品重量',
    join_collection_point: '加入取货点',
    merchant_product_discoverability: '商家产品的可发现性',
    out_of_stock: '缺货',
    packaging: '包装',
    per_unit_price: '单价',
    pooling: '需要的买家',
    product_description: '产品描述',
    quantity: '数量',
    see_less: '显示更少',
    see_more: '查看更多',
    select_variation: '选择变体',
    sharing_dialog_title: '分享产品',
    sharing_text: '立即在 TreeDots for Merchants 上查看 %{product_name}！\n',
    sharing_title: '您可能会喜欢 TreeDots for Merchants 的 %{product_name}！',
    similar_product: '相似产品',
    sold_out: '售罄',
    stock: '库存',
    total_price: '总价',
    uom_default: '单位',
    variation: '变化',
    view_and_order: '查看并订购',
    view_and_order_hint: '商家用户可以查看产品并自行提交订单。',
    view_only: '仅查看',
    view_only_hint: '客户只能查看产品。他们需要联系供应商才能提交订单。',
    view_supplier: '查看供应商',
    volume_deal: '批量优惠',
    volume_deal_description: '当取货点达到一定数量时，可享受折扣价并获得每件购买商品的现金返还！',
    volume_deal_details: '批量交易详情'
  },
  product_found: '找到的产品',
  product_have_purchased: '您已购买的产品',
  product_in_other_collection_point: '产品在<b>%{collection_point_name}</b>有售。',
  product_info: '产品信息',
  product_information: '产品信息',
  product_name: '产品名称',
  product_name_a_z: '商品名称 A-Z',
  product_name_z_a: '产品名称 Z-A',
  product_price_update: '产品价格更新',
  product_stock_update_desc:
    '当该产品的供货情况发生变化时，您将在第一时间获悉！（例如，已补货、库存有限或缺货）',
  product_stock_update_label: '产品库存更新时请通知我',
  product_variation: '产品变化',
  product_weight: '产品重量',
  products: '产品',
  products_found: '找到的产品',
  products_result: '结果',
  products_updates: '产品更新',
  products_updates_notification: '产品更新通知',
  profile: '个人资料',
  profile_updated: '资料已更新',
  promos: '促销',
  prompt_select_collection_point: '请选择取货点！',
  providing: {
    content_1:
      '2020 年，在 Covid-19 疫情期间，我们将供应商直接与终端消费者联系起来，并以此改进了我们的平台。我们引入了 ',
    content_1a:
      '现在，家庭和个人买家可以以更低的价格享受我们供应商的产品，并与我们一起积极参与解决食物浪费问题。',
    content_2:
      '终端消费者正在与友邻一起团购产品。因此，供应商无需提供散装货物，可立即将订单配送到社区，同时减少碳排放，带来更有效的再分配方式。',
    groupbuy_app: '团购应用。 ',
    title: '为家庭提供服务'
  },
  pull_to_refresh: '下拉刷新',
  price_shown_tax_inclusive: '价格已包含税',
  price_shown_before_tax: '价格不包含税',
  purchase_auth: '购买认证',
  qoted_by: '报价方',
  quantity: '数量',
  quotation: '报价',
  quotationApproved: '报价已获批准',
  quotationPendingContent: '由于您的报价低于设定的限值，因此该请求已转发给您的供应商以供进一步审批。',
  quotationPendingTitle: '您的报价正等待批准',
  quotation_has_been_sent: '报价已发送！',
  quotation_limit_price: '报价限价',
  quotation_notification: '报价通知',
  quotation_price: '报价',
  quote_price: '报价',
  quote_price_great_direct_price_agree: '是的，继续',
  quote_price_great_direct_price_body: '客户收到的报价将高于产品的直接价格。是否确定？',
  quote_price_great_direct_price_title: '报价高于直接价格？',
  quoted: '已报价',
  quotedBy: '报价的用户',
  quoted_price: '报价',
  re_enter_password: '重新输入密码',
  read_more: '了解详情',
  receive_your_order: '我们已收到您的 {isOrderBooking}',
  received_your_problem: '我们已收到您的请求。请等待我们的工作人员的回复。',
  recent_searches: '最近的搜索记录',
  recipient: '收件人',
  reference_code: '参考代码（可选）',
  reference_number: '参考编号',
  referral: '推荐',
  referral_code_label: '推荐（可选）',
  referral_code_placeholder: '推荐码',
  refresh: '刷新',
  refund_breakdown: '退款明细',
  refund_description1: '对于使用优惠券支付的商品，退款金额不包括优惠券金额。',
  refund_description2: '您可以在此处查看退款明细。',
  refund_tooltip1: '用户结算时用的优惠券数额',
  refund_tooltip2: '我们退款的订单中取消的商品数量',
  refund_tooltip3: '退款总额',
  refunded_amount: '退款金额',
  refunded_qty: '退款数量',
  refunds: '退款',
  refunds_policy: {
    or: '或 ',
    p1: '如果您在订购产品后改变主意，TreeDots 允许取消和退款。这仅适用于团购未达到最低限额的商品。',
    p2:
      '在订单中，符合取消条件的商品必须处于“准备中”的状态。状态为 “待取货” 的商品无法因您改变主意就可取消。',
    p3: '所有因改变主意而导致的退款都要遵守以下条款和条件：',
    p3_1: '订单中的商品必须处于 “准备中”的状态，才能取消订单并退款',
    p3_2: '商品必须属于团购，但未达到最低限额',
    p3_3: '此退款政策不适用于标明为 “待取货” 且已达到团购最低限额的商品',
    p3_4: '对于使用优惠券的商品，退款金额不包括优惠券金额',
    p4a: '取消后，已取消商品的退款将在取消后立即处理，并退回到您结算商品时使用的付款方式。 ',
    p4b: '您可以在订单的 “已取消” 部分找到有关商品的退款信息。',
    p5:
      '如果您希望取货点负责人全程代替您取消商品，您可以通过应用内聊天功能请求负责人代为操作。如果收到有缺陷或不完整的商品，请联系您的取货点负责人为您申请退款。',
    p6: '如果您由于技术问题而无法取消订单，请联系我们的 ',
    refunds_take: '退款需要 7-10 个工作日才能显示在您的银行账户中。 ',
    tech_call: '通过电话获得技术支持 ',
    title: 'TreeDots 退款政策',
    whatsapp: 'WhatsApp 联系号码：+65 31384846。',
    whatsapp_my: 'WhatsApp 联系号码：+65 89145600。',
    whatsapp_sg: 'WhatsApp：+65 31384846。'
  },
  register_today: '立即注册！',
  register_your_company: '立即注册您的公司以购买产品',
  registered_mobile_go_to_login: '您的手机号码已经在我们平台注册了，您想用这个手机号码登录吗？',
  regular_price: '常规价格',
  reject: '拒绝',
  reject_pre_success: '拒绝预订成功',
  rejected: '已拒绝',
  rejectedBy: '拒绝者',
  relevant: '相关',
  remaining_credits: '剩余积分：',
  reminder_spree_close_date_and_collection_date:
    '在<b>%{close_date}</b>之前购买，以便在<b>%{collection_date}</b>之前取货！',
  remove_card: '移除卡片',
  remove_confirm: '确定要删除此卡片吗？',
  remove_mess: '移除此商品？',
  remove_product_from_cart: '从购物车中移除商品？',
  removed: '已移除',
  removedSuccessfully: '已成功移除。',
  reply: '回复',
  report_your_problem: '报告您的问题',
  requestAt: '请求于',
  requestQuotationDetails: '请求报价详情',
  request_again: '再次请求',
  request_approval: '请求批准',
  request_approve: '请求批准',
  request_at: '请求时间',
  request_detail_let_us_know: '如果您对客户积分分配有任何要求，请在此处告诉我们。',
  request_detail_optional: '请求详情（可选）',
  request_detail_placeholder: '例如：我想将所有可用的客户信用额度存入发票 A，而不应用于发票 B',
  request_details_only: '请求详情',
  request_quotation: '请求报价',
  request_quotation_details: '查看报价详情',
  request_quotation_failed: '请求报价失败',
  request_reject: '请求被拒绝',
  request_sent: '请求已发送！',
  request_transaction: '请求交易详情',
  requested: '已请求',
  required: '（必填）',
  resend_code: '重新发送验证码',
  resend_code_in: '在 {duration} 内重新发送验证码',
  reset_filter: '重置过滤条件',
  reset_password: '重置密码',
  reset_search_filter: '重置过滤条件',
  result_for: '<strong>"<i>{search_key}</i>"</strong> 的搜索结果',
  result_from: '来自<strong>产品类别的结果：{category_name}</strong>',
  return_to_cart: '返回购物车',
  salesperson: '供应商联系人',
  sample: '样品',
  sampleProduct: '样品产品',
  sample_product: '[免费样品]',
  save: '保存',
  save_address: '保存地址',
  save_changes: '保存更改',
  saved_successfully: '保存成功',
  seafood: '海鲜',
  search: '搜索',
  search_all_products: '搜索所有产品',
  search_collection_point_via_map: '通过地图搜索取货点',
  search_company: '搜索公司或账号',
  search_company_admin: '搜索公司、PIC 或账号',
  search_customer_admin: '搜索公司、PIC 或账号',
  search_company_not_found: '很抱歉，未找到公司',
  search_for: '搜索',
  search_for_food: '搜索食物',
  search_in: '在以下位置中搜索',
  search_in_ang_mo_kio: '在宏茂桥中搜索',
  search_items: '搜索商品...',
  search_location: '在此处输入位置',
  search_results_for: '的搜索结果',
  search_suppliers: '搜索供应商...',
  search_term: '搜索词。',
  secure_checkout: '安全付款',
  see_all: '查看全部',
  see_more: '查看更多',
  select: '选择',
  selectCompany: {
    branches: '分公司',
    change: '更改',
    change_company: '更改公司',
    main_company: '主要公司',
    products: '产品',
    select_company: '选择公司'
  },
  select_customer: '选择客户',
  select_address: '选择地址',
  select_all: '全选',
  select_another_one: '请选择另一项！',
  select_another_option: '选择其他选项',
  select_business_profile: '选择企业简介',
  select_collection_point: '选择取货点',
  select_company_pic_name: '选择公司或 PIC 姓名',
  select_date: '选择日期',
  select_delivery_date: '设置配送日期',
  select_pickup_date: '选择自提日期',
  select_delivery_time: '选择配送时间',
  select_pickup_time: '选择自提时间',
  select_invoice: '选择发票',
  select_other_company: '选择其他公司',
  select_other_variant: '选择其他变化',
  select_payment: '选择付款方式',
  select_payment_method: '选择付款方式',
  select_person: '选择人员',
  select_preferred_language: '选择首选语言',
  select_reason: '选择原因',
  select_state: '立即选择您的首选购物区，享受其中的精选产品。',
  select_valid_action: '请为您的问题选择有效的理由',
  select_valid_remedy: '请为您的问题选择有效的补救措施',
  select_variation: '选择变化',
  selected_collection_point: '选择的取货点',
  selected_count: '{selected_count} 已选择',
  selection: '选择',
  seller: '卖家',
  selling_price: '销售价格',
  send: '发送',
  send_document: '发送文档',
  send_feedback: '发送反馈',
  send_photo: '发送照片',
  send_verification_code: '发送验证码',
  send_via_email: '通过电子邮件发送',
  send_via_peppol: '通过 Peppol 网络发送',
  separate_invoice: '单独开发票',
  set_as_default_address: '设为默认的账单地址',
  set_birth_date: '设置出生日期',
  set_default: '设为默认卡',
  set_main_card: '设置主卡',
  set_new_price: '设定新价格',
  set_password: '设置密码',
  set_password_for_sub_buyer: '为分买方设置密码',
  set_pwd: '设置密码',
  set_sample_product: '设为免费样品',
  set_time: '设置配送日期和时间',
  set_time_self_pickup: '选择自提日期和时间',
  settings: '设置',
  share: '分享',
  share_via: '通过以下方式分享',
  show_less: '显示更少',
  show_more: '显示更多',
  sign_in: '登录',
  sign_in_failed: '这似乎不是正确的密码或电子邮箱。请重试或点击 “忘记密码”。',
  sign_in_with_mobile_phone: '使用手机号码登录',
  sign_in_with_password: '使用密码登录',
  sign_out: '退出登录',
  sign_out_confirmation: '退出确认',
  sign_up: '注册',
  sign_up_instead: '直接注册',
  similiar_product: '相似产品',
  singapore: '新加坡',
  skip: '跳过',
  sku: 'SKU',
  snacks: '零食',
  social_shared_mess: '您好，TreeDots 提供质优价廉的各种库存商品。\n\n立即在以下位置下载',
  soldInUnits: '以 {number} 为单位出售',
  some_item_no_available: '抱歉，部分商品目前缺货或不再供应',
  some_items_not_favorite: '有些商品不在 “收藏夹” 中。',
  something_went_wrong_please_try_again: '出错了，请重试！',
  sorry: '抱歉！',
  sorry_could_not_find: '抱歉，找不到',
  sorry_not_any_products: '抱歉，搜索不到任何产品！',
  sorry_not_found: '抱歉，我们找不到 “{search_keyword}”',
  sorry_went_wrong: '很抱歉，出错了',
  sort: '排序',
  special_price: '特价',
  spelling: '拼写',
  stall: '摊位',
  stallNo: '摊位号（可选）',
  staples: '主要产品',
  start_buying_choose_cp: '如需开始团购，请选择您的取货点。',
  start_purchasing_today: '立即开始购买',
  started_with_avocados: {
    content_1:
      '其中一位创始人过去一直在一家商品贸易和航运公司工作，某天由于不可预见的情况，导致 24 吨鳄梨不得不在中国的一个港口里腐烂变质。',
    content_2:
      '公司创始人无法接受。这就催生了创立 TreeDots 的想法。我们想创立一家公司，来拯救高质量的新鲜食品，使其不至于因为过时的监管做法而被丢弃浪费。',
    title: '一切始于鳄梨'
  },
  state: '州/省',
  stay: '取消',
  stay_tuned: '敬请期待！',
  step1_add_company: '第 1 步：添加公司',
  step2_we_will_review: '第 2 步：我们将审核并批准您的公司',
  step3_start_ordering: '第 3 步：开始订购！',
  stock_locations: '库存地点',
  street: '街道',
  street_number_cannot_be_empty: '街道号码不能为空',
  sub_total: '小计',
  submit: '提交',
  submit_information_about_other_supplier: '提交其他供应商的信息',
  submit_report_mess: '我们已收到您的请求。请稍候，我们的工作人员将尽快回复您。',
  subtotal: '小计',
  success: '成功',
  successfully_added_to_cart: '已成功加入购物车。',
  successfully_connected_to_company: '成功连接到公司',
  success_replace_contact_person: '成功更换为销售联系人',
  sucess_msg_color: '主要',
  summary: '摘要',
  supplier: '供应商',
  support: '技术支持',
  support_information: '证明资料/理由',
  sweets: '糖果',
  switch_collection_point: '切换取货点',
  sync_state: {
    halal: '清真认证',
    non_halal: '非清真',
    not_applicable: '未经清真认证'
  },
  system_outage_head_text: '我们暂时停止运作 😫',
  system_outage_sub_text: '我们的团队正在努力恢复平台，请给我们一些时间来修复。',
  tab_1: '选项卡 1',
  tabs: {
    account: '帐户',
    cart: '购物车',
    categories: '类别',
    home: '首页',
    orders: '订单'
  },
  takeAction: '采取行动',
  take_photo: '拍照',
  tap_here_to_view_description: '点击此处查看描述',
  tap_to_view_info: '点击以查看信息',
  tax: '税费',
  tax_amount: '所有价格均包含消费税',
  tax_included: '含税',
  tech: '技术支持',
  telegr: 'Telegram',
  telegr_alt_img: 'telegram',
  telegr_link_to_share:
    'https://telegram.me/share/url?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload&amp;url=',
  telegr_logo_url: 'https://cdn3.iconfinder.com/data/icons/social-icons-33/512/Telegram-256.png',
  telegr_share_link: 'https://t.me/TreeDotsBot',
  tell_a_friend: '推荐给朋友',
  tell_us_about_your_business: '介绍您的企业概况',
  terms: '使用条款',
  terms_of_use: {
    collection: '5. 取货',
    effective: '自 2020 年 5 月 14 日起生效。',
    eligibility: '3. 资格',
    governing: '10. 适用法律和管辖权',
    inability: '6. 无法取货',
    intelectual: '2. 知识产权',
    intro: '1. 简介',
    intro_1:
      '这些是使用和销售条款（“条款”），供您使用由 TreeDots Enterprise（Private Limited）和 TreeDots International（Private Limited）（统称为“TreeDots”）拥有、控制和管理的网站，包括 www.thetreedots.com 和任何 TreeDots 移动应用程序（"网站"）。随着我们的服务范围的扩大，我们可能会不时地增加其他网站，这些条款将在添加这些新网站时受这些条款的约束。',
    intro_2:
      '这些条款是您与 TreeDots 之间的协议，包括但不限于作为内容、信息和其他材料的卖家、买家、商家或贡献者的用户。如果您是为某一企业实体并代表其签订本协议，则本协议中的 "您 "应指您代表其使用网站的企业实体，除非上下文不允许。',
    limitations: '9.责任范围',
    miscell: '11. 其他',
    p10_1:
      '您特此同意，本条款受新加坡法律管辖，新加坡法院对由本条款引起或与之相关的任何争议拥有非专属管辖权。',
    p11_1:
      '您和 TreeDots 是独立承包商，本条款不打算或没有创建任何代理、合伙企业、合资企业、雇员-雇主或特许经营者-加盟商关系。',
    p11_2:
      '如果本条款的任何条款被认定为无效或不可执行，其余条款的合法性、有效性和可执行性将不会受到影响或损害。',
    p11_3:
      '如果 TreeDots 未能行使权利或要求履行本条款中的义务，则不会影响 TreeDots 在此后的任何时间行使此类权利或要求履行此类权利的能力，也不放弃豁免任何后续违约行为。',
    p11_4:
      '未经我们事先书面同意，您不得转让此处规定的任何权利或委托此处规定的任何义务，任何违反本规定的转让或委托企图都是无效的，没有任何效力或作用。',
    p11_5: '这些条款不向非本条款当事方的任何第三方赋予任何权利。',
    p1_1:
      '1.1 您可能是通过电脑或移动设备（例如通过任何TreeDots移动应用程序）访问我们的网站。这些条款适用于您对我们网站的使用和您的行为，无论您通过何种方式访问。',
    p1_2:
      '1.2 我们可能会在第三方网站（包括我们发布内容的社交媒体网页）上与您互动，例如 http://www.facebook.com/thetreedots/ 等。TreeDots 不控制这些社交网站，且这些条款不适用于TreeDots 不拥有或控制的公司，也不适用于TreeDots 不雇用或管理的人员的行为。您应始终查看社交网站上发布的使用条款。',
    p1_3:
      '1.3 我们要求您在访问或使用我们的网站或通过网站购买任何产品之前，仔细阅读这些条款和我们的隐私政策。如果您对我们在线浏览和购物有任何疑问，请参阅我们的常见问题（“FAQ”）、“订购和取货” 部分或联系我们。',
    p1_4:
      '1.4 通过使用网站，您同意这些条款，以及我们的隐私政策、常见问题、订购与取货和退款政策的规定。TreeDots保留在任何时候，或在适用法律要求的情况下，对条款中的任何条款和条件进行更改或修改的权利。对TreeDots使用条款的做出任何变更或修改将在修订内容公布后生效。适用于通过本网站下达的任何订单的TreeDots使用条款是在您下达订单时适用的TreeDots使用条款。您在任何变更或修改公布后继续使用本网站，即表示您接受这些变更或修改。如果您不同意TreeDots的使用条款或这些变更或修改，您必须立即停止使用网站。',
    p2_1: '2.1 您只能按照 TreeDots 使用条款允许的方式使用网站。',
    p2_2: '2.2. 您不得：',
    p2_2a: '2.2.1. 复制、反编译、解析、反汇编、尝试获取源代码、解密、干扰或破坏网站的完整性或性能；',
    p2_2b: '2.2.2. 对网站进行或尝试任何修改、改编、改进、增强、翻译或衍生工作；',
    p2_2c: ' 2.2.3. 违反与您访问或使用网站有关的任何适用法律、法规或条例；',
    p2_2d: '2.2.4. 将网站用于任何非设计或意图的目的；',
    p2_2e:
      '2.2.5. 使用网站发送、发布或以其他方式传播任何冒犯性、不雅、威胁、辱骂、侮辱、骚扰、诽谤、中伤、欺骗、欺诈、侵权、淫秽、亵渎、侵犯他人隐私，或在种族、民族或其他方面令人反感的内容。',
    p2_2f:
      '2.2.6. 使用网站发送自动、未经请求或未经授权的消息、广告或促销材料或任何垃圾邮件、垃圾信息或连锁信。',
    p2_2g:
      '2.2.7. 使用或尝试使用任何抓取工具、机器人、蜘蛛爬虫、爬虫或任何其他自动化设备或手段来访问、获取、复制或监视网站的任何部分，或通过网站发现或访问的任何数据或内容；',
    p2_2h: '2.2.8. 未经其他用户同意，收集有关其他用户的任何信息；',
    p2_2i: '2.2.9.实施任何行为以避免支付任何适用的费用和/或收费。',
    p2_2j:
      '2.2.10. 试图和/或从事任何旨在滥用或不恰当地操纵通过网站提供的任何促销、活动和/或折扣代码的活动或行为。此类行为和活动包括但不限于：创建虚假或重复帐户;',
    p2_2k: '生成虚假订单；',
    p2_2l: '2.2.11. 授权或鼓励任何人进行上述任何一项行为;',
    p2_3:
      '2.3.网站的所有知识产权（包括但不限于版权、商标、专利、设计权和所有其他形式的知识产权）均归 TreeDots 所有。此外，您确认并同意，网站的源代码和目标代码以及网站的格式、目录、查询、算法、结构和组织均属于 TreeDots 的知识产权和专有及保密信息。',
    p2_4:
      '2.4. 网站可能使用或包含受开放源码和第三方许可条款约束的第三方软件（“第三方软件”）。您确认并同意，您使用该第三方软件作为网站的一部分的权利受适用于该第三方软件的开放源码和第三方许可的条款和条件的制约，包括但不限于其中包含的任何适用的确认书、许可条款和免责声明。如果这些条款与这些开放源码或第三方许可证的条款发生冲突，在您使用相关第三方软件时，应以开放源码或第三方许可证的条款为准。在任何情况下，网站或其组成部分都不应被视为开放源码或公开可用的软件。',
    p3_1:
      '3.1 为了能够在我们的网站上进行购物，您需要向我们提供您的个人详细信息，特别是您的姓名、电子邮件地址、电话号码和/或其他联系方式。',
    p3_2:
      '3.2 您提供给我们的信息或个人详细信息将根据您的订单中的隐私政策进行处理。当您使用我们的网站时，即表示您同意处理您的信息和详细信息，并确认您提供的所有信息和详细信息都是真实准确的。',
    p3_3:
      '3.3 当您通过我们的网站下订单时，您确认您已年满 18 岁，并且在法律上有资格签订具有约束力的合同。如果您未满 18 岁，您只能在得到您父母或监护人的同意后才能下订单。如果您没有得到您父母或监护人的同意，您必须停止使用本网站。',
    p3_4: '3.4 请注意，如果您没有向我们提供所需信息，则无法下单或处理订单。',
    p4_1:
      '4.1 网站上的产品信息和描述由在我们平台上销售的相关供应商提供。在这种情况下，TreeDots 没有独立核实供应商声明的产品信息，不对此类声明负责。',
    p4_2:
      '4.2 虽然我们尽量提供准确的产品描述，但我们不保证网站上的描述、信息、价格、促销活动或其他内容是准确、最新或没有错误。如果网站上的任何信息不准确，TreeDots 可随时纠正任何错误、不准确或遗漏，并更改或更新信息或取消订单，而无需事先通知，包括在您提交订单之后。',
    p4_3: '4.3 如果您收到错误、不完整或有缺陷的产品，TreeDots的退款政策将适用。',
    p4_4:
      '4.4 网站上显示的价格以当地货币计算（新加坡地区为新加坡元（SGD），马来西亚地区为马来西亚林吉特（RM）），包含商品及服务税（GST），并包含供应商配送到取货点的运费。',
    p4_5:
      '4.5 产品价格将始终按照我们网站上的规定进行，除非出现明显的错误。尽管我们会尽一切努力确保网站上的价格正确无误，但仍可能出现错误。如果我们发现您订购的任何产品的价格有误，我们将尽快通知您。如果 TreeDots 无法与您联系，您的订单可能会被取消，您支付的金额将通过您使用的原始付款方式全额退还。',
    p4_6: '4.6 关于TreeDots的付款方式，请参考常见问题和订购与取货部分。',
    p5_1:
      '5.1 网站上显示的产品基于您注册的国家/地区代码。这些产品只能在新加坡和马来西亚订购，并且只能自行取货。如果您需要有关 TreeDots 取货条款的更多信息，请参阅我们的“常见问题”或“订购和取货”部分。',
    p6_1: '6.1 如果您无法收到包裹，请参阅我们的“常见问题”和“退款”部分。',
    p7_1: '7.1 所有退货必须按照我们的“常见问题”和“退款”部分中列出的说明进行。',
    p8_1: '8.1 您必须年满 18 岁才能成为注册用户。',
    p8_2: '8.2 您同意仅提交或提供准确真实的信息，并且您将及时更新所提供的信息。',
    p8_3:
      '8.3 在我们的网站上注册TreeDots帐户时，您需要设置一个帐户密码。您有责任确保包括密码在内的帐户详细信息保持机密、最新、完整和准确。您应对在您的帐户内和/或使用您的密码（无论是否授权）发生的所有活动负责，正如您是操作方。如果您意识到或有理由怀疑您的帐户详细信息（包括密码）的机密性已被泄露，或者您的帐户遭到任何未经授权的使用，或者您的个人数据需要更新，则应立即通知 TreeDots。',
    p8_4:
      '8.4 您可以将信用卡信息作为账户的一部分进行存储，以便加快结账流程。这些信息的收集和处理受我们的隐私政策的约束。',
    p8_5:
      '8.5 如果您代表其他人、公司、组织或法人实体（“实体”）访问和使用网站，则表示您有权约束该实体作为TreeDots 条款的委托人。如果您没有此类权限，则表示您同意受这些条款的约束，并对因使用本网站而造成的损害承担责任。',
    p8_6: '8.6 TreeDots 保留因任何原因（包括违反 TreeDots 条款）暂停或终止您的账户的权利，而无需事先通知。',
    p8_7: '8.7 您可以随时通过网站停用您的账户。',
    p8_8:
      '8.8 您确认并同意，如果您停用帐户，或者如果 TreeDots 暂停或终止您的帐户，您将丢失与您的帐户相关的任何信息。当账户终止后，本条款中授予您的所有权利将立即终止。',
    p9_1: '您确认并同意，对于本网站的任何问题或不满，您的唯一权利是要求终止您的帐户和/或停止使用任何服务。',
    p9_2:
      '在任何情况下，TreeDots 或其子公司、关联公司、合作伙伴、供应商或许可方均不对因您访问或使用或无法访问服务或任何第三方内容和服务而引起或与您或其他用户在使用本网站时的行为与之相关的任何间接性、偶然性、后果性、特殊性或惩戒性损害负责，无论此类损害是否可预见，且无论 TreeDots 是否被告知此类损害的可能性。在不限制前述规定的一般性的前提下，TreeDots 对您承担的总责任（无论是根据合同、侵权行为、法规还是其他方式）不得超过TreeDots 因引起此类索赔的服务而收取的费用金额。即使上述补救措施未能实现其基本目的，上述限制也将适用。',
    p9_3:
      'TreeDots 不保证或声明其评估或监控任何用户或第三方供应商的适用性、合法性、能力、活动或位置，并且您明确放弃和免除 TreeDots 因用户或第三方提供商引起或以任何方式与之相关的任何和所有责任、索赔或损害。',
    p9_4:
      '本条款中的任何内容均不适用于或以任何方式限制或排除 TreeDots 在以下情况下的责任：(a) 因其疏忽造成的死亡或人身伤害；(b) 不诚实、欺骗或欺诈性虚假陈述；或 (c) 任何适用法律法规可能无法限制或排除的责任。',
    product: '4. 产品信息和价格',
    refunds: '7. 退款',
    title: 'TreeDots 使用条款',
    your_account: '8. 您的帐户'
  },
  test_enterprise_branch: 'TEST-TreeDots Enterprise-Branch',
  text_copied_to_clipboard: '文本已复制到剪贴板',
  text_remove_item_in_cart: '该商品将从您的购物车中移除',
  thank_you_first_company: '感谢您添加您的公司！',
  the_date_that_effective: '交易生效的日期。',
  this_branch: '该分店',
  this_field_must_be_a_valid_email: '此字段必须填写有效的电子邮箱',
  this_will_be_added_to_all_user: '将此添加到所有用户的公司，并设置为此设备上的默认付款方式',
  title_check_duplicate: '合并订单？',
  to: '收件人',
  toast_color: '主要',
  toast_color_error: '危险',
  today: '今天',
  tomorrow: '明天',
  tooltip_message_end: '从所列出的内容来看。',
  tooltip_message_four: '本产品可能有所不同',
  tooltip_message_second: '大小不一。最后的',
  tooltip_message_start: '本产品自然',
  tooltip_message_third: '的',
  top: '热门',
  top_picks: '热门精选',
  top_up_credits: '充值积分',
  topup: '充值',
  total: '总计',
  total_amount: '总金额',
  total_bill: '账单总额',
  total_invoice_amount: '发票总金额',
  total_new_price: '新的总价',
  total_order: '订单总额',
  total_paid_amount: '付款总额',
  total_payment: '付款总额',
  total_price: '总价',
  total_weight: '总重量',
  track_booking: '跟踪预订',
  track_driver_location: '查看司机位置',
  track_order: '追踪订单',
  track_order_update_status: {
    new: '新建',
    removed: '已删除',
    replaced: '已替换',
    updated: '已更新'
  },
  transaction: '交易',
  transaction_date: '交易日期',
  transaction_has_been_cancelled: '交易已取消！',
  transaction_reference_number: '交易参考编号',
  treedots_groupbuy: 'TreeDots 团购',
  treedots_story: 'TreeDots 故事',
  treedotspay: 'TreeDots Pay',
  try_again: '重试',
  try_different_keyword: '请尝试不同关键字或重新输入新的筛选条件',
  try_different_name: '请尝试不同的公司名称或账号',
  try_different_name_admin: '请尝试不同的公司名称、PIC 或账号',
  twitter: 'Twitter',
  twitter_alt_img: 'twitter',
  twitter_link_to_share:
    'https://twitter.com/intent/tweet/?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload&amp;url=',
  twitter_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/twitter-256.png',
  type_message: '在此处输入消息',
  type_your_company_cuisine: '请输入贵司的菜肴',
  un_paid: '未付款',
  unable_booking_sample: '无法为商品样本创建预订。',
  unable_create_booking_body: '抱歉，无法为没有库存的商品创建预订',
  unable_create_booking_message: '在继续操作之前，请将这些商品移除，或联系您的管理员了解更多信息',
  unable_create_booking_title: '无法创建预订',
  unable_load: '无法加载',
  unable_set_as_default: '无法设为默认设置',
  unable_to_authorise: '无法授权',
  unable_to_authorize: '无法授权',
  unable_to_cancel: '无法取消',
  unable_to_connect: '无法连接',
  unable_to_place_order: '无法下单',
  unavailable: '不可用',
  unavailable_product: '不可购买的产品',
  understood_delete_account: '明白了，删除我的账户',
  undo: '撤消',
  unit: '单位',
  unitNo: '单元号（选填）',
  unitPriceCannotBeEmpty: '单价不能为空',
  unit_price: '单价',
  unmute: '解除静音',
  unpaid: '未付款',
  unpaid_invoices: '未付发票（{unpaidInvoiceAmount}）',
  update: '更新',
  updateOrder: '更新订单',
  updateProfile: {
    birth_date_cannot_be_empty: '出生日期不能为空',
    email_cannot_be_empty: '电子邮箱不能为空',
    gender_cannot_be_empty: '性别不能为空',
    phone_number_must_be_at_least_5_characters: '电话号码必须至少有 5 个字符'
  },
  update_address: '更新地址',
  update_amount: '更新金额',
  update_app: '更新应用程序版本',
  update_by_user: '{userName}（{userType}，用户 ID：{userId}）',
  update_changes: '更新更改',
  update_information: '更新信息',
  update_item_success_mess: '商品状态更新成功！',
  update_modal_header: '有新的更新!',
  update_modal_message: '我们改进了我们的团购应用。请更新以继续。',
  update_payment: '更新付款',
  update_pre_order: '更新预订',
  update_price: '更新价格',
  update_profile: '更新资料',
  update_user_profile: '更新用户资料',
  update_type: {
    admin: '管理员',
    merchants: '商家',
    sales: '销售员',
    supplier: '供应商',
    system: '系统',
    you: '您'
  },
  updated: '已更新',
  updated_by: '更新者',
  upload_file: '上传文件',
  use_available_credits: '使用可用积分',
  use_customer_balance: '使用客户余额',
  use_this_postal_code: '使用此邮政编码',
  user_not_found_for_mobile: '您还未注册账户。请注册后再访问我们的平台。',
  username: '用户名',
  valid_till: '有效期至',
  validation: {
    confirm_password: '输入的密码不匹配',
    country_code: '选择国家/地区代码',
    email: '请输入有效的电子邮件地址',
    first_name: '名字必须超过 2 个字符',
    password: '密码必须超过 6 个字符',
    phone: '请输入有效的电话号码'
  },
  variation: '变更：',
  variations: '变化',
  vegetables: '蔬菜',
  veggies: '蔬菜',
  vendor_connect: '供应商连接！',
  verify_mobile_num: '验证手机号码',
  verify_phone_number: '验证电话号码',
  verify_your_number: '验证您的号码',
  verify_your_email: '验证您的电子邮件',
  view: '查看',
  view_all: '查看全部',
  view_all_collection_points: '查看所有取货点',
  view_all_order: '查看所有订单',
  view_details: '查看详细信息',
  view_dispute_status: '查看争议状态',
  view_driver_location: '在地图上查看司机位置',
  view_image: '查看图片',
  view_invoice: '查看发票',
  view_order: '查看订单',
  view_payment_history: '查看付款历史记录',
  view_product: '查看产品',
  view_similar_products: '查看类似产品',
  visa_card_debit: 'Visa 卡（借记卡）',
  vision: '到 2025 年，TreeDots 希望节省 200 万吨食物，从而将碳排放量减少 1800 万吨。',
  volume_deal: '批量优惠',
  volume_deal_s: '批量交易',
  voucher: '优惠券',
  voucher_amount: '优惠券总计',
  voucher_applied: '已用的优惠券',
  voucher_deduction: '优惠券折扣',
  vouchers: '优惠券',
  vouchers_expiring_soon: '优惠券即将到期',
  wa: 'WhatsApp',
  wa_alt_img: 'whatsapp',
  wa_link_to_share:
    'whatsapp://send?text=Hi%2C%20TreeDots%20provides%20high%20quality%20and%20cheap%20inventories.%0A%0ADownload%20now%20at%0Ahttps%3A%2F%2Fthetreedots.com%2Fdownload',
  wa_logo_url: 'https://cdn4.iconfinder.com/data/icons/miu-flat-social/60/whatsapp-256.png',
  wa_share_link: 'https://wa.me/6531384846',
  wait_update: '请等待此更新完成。',
  waiting_for_approval: '等待租户批准',
  wallet: {
    add_bank_account: '添加银行账户',
    add_bank_fail: '添加新的银行帐户失败！',
    add_bank_success: '成功添加新的银行账户!',
    add_new_bank_account: '添加新的银行账户',
    amount_exceeds: '此金额超过了最大可转账金额。可转账金额：',
    available_withdraw: '可用提款余额',
    balance: '余额',
    completed_withdraw: '您已完成提现！',
    info_withdraw: '可用资金是什么意思？',
    is_required: '为必填项！',
    item_refund: '商品退款',
    link_bank: '关联您的银行账户',
    load_withdraw: '您的提款正在处理中',
    main_info_withdraw: '任何通过捐赠获得的余额都不允许提取。您仍然可以在应用内使用这些余额进行付款。',
    minimum_withdraw: '最低提款金额为 0.11 美元',
    no_transaction: '您还没有任何交易清单。',
    order_id: '订单 ID',
    order_payment: '订单付款',
    order_refund: '订单退款',
    primary_refunds: '设为主要退款方式',
    savings: '节省：',
    select_bank: '选择银行',
    something_wrong: '出了点问题...这笔交易似乎有问题。请稍后再试。',
    to: '至',
    top_fail_info: '您的充值交易失败。您使用的付款方式似乎有问题。请重试或选择其他方式。',
    top_up: '充值',
    top_up_page: {
      add_new_bank_account: '添加新的银行账户',
      bank_transfer: '银行转账',
      bank_transfer_page: {
        add_bank_page: {
          account_name: '帐户名称',
          account_number: '账号',
          account_type: '账户类型',
          add_dbs_ocbc: '添加 DBS / OCBC 账户',
          bank_code: '银行代码',
          branch_code: '分行代码',
          current_account: '活期账户',
          instruction_1: '登录您的银行应用程序',
          instruction_2: '选择转账至<strong>本地银行账户</strong>选项，然后选择<strong>添加收款人</strong>',
          instruction_3: '选择使用<strong>银行账户</strong>详情添加收款人',
          instruction_4: '在银行名称下，选择<strong>TREEDOTS ENTERPRISE PTE LTD</strong>。',
          instruction_5: '输入您的帐号',
          instruction_5_1: '6591118888',
          instruction_5_2: '<strong>复制</strong>',
          instruction_6: '确保账号正确无误',
          instruction_7:
            '输入您的<strong>收款人姓名</strong>并<strong>确认</strong>，然后选择<strong>向收款人转账</strong>',
          instruction_8: '输入您的充值金额，向收款人留言并确认',
          instruction_9: 'TreeDots 将在 30 秒内收到您的充值金额',
          saving_account: '储蓄账户',
          success: '现在，您可以在 TreeDots 使用您的银行账户',
          top_up_via: '通过银行转账充值',
          verified: '已验证！',
          we_have_transferred: '我们已将部分款项转入上述账户。已收金额：'
        },
        amount: '金额',
        amount_tip: '确保您发送的金额与所示金额完全相同。',
        copy: '复制',
        instruction_1: '登录您的 ibanking 页面，然后按照以下信息转账。',
        instruction_2: '按照以下信息添加新收款人。',
        instruction_3: '成功收到付款后，此发票将在 5 分钟内自动更新。（您可能需要刷新页面）。',
        instruction_title: '转账说明：',
        recipient_account_number: '收款人账号',
        recipient_account_number_placeholder: '6591118888',
        recipient_account_number_tip: '提示：检查您在 ibanking 页面上输入的账号是否准确。',
        recipient_bank: '收款银行',
        recipient_bank_placeholder: 'CIMB',
        recipient_name: '收款人姓名',
        recipient_name_placeholder: 'TreeDots',
        top_up_via: '通过银行转账充值'
      },
      card_page: {
        maximum_top_up: '最高充值金额为',
        minimum_top_up: '最低充值金额为',
        top_up_amount: '充值金额：',
        top_up_via: '通过信用卡/借记卡充值'
      },
      complete_page: {
        paid_total: '已付总额 ',
        the_top_up_amount: '充值金额将更新并显示在您的钱包中，这可能需要几分钟时间',
        title: '您已完成充值！'
      },
      credit_card_debit_card: '信用卡/借记卡',
      fpx: 'FPX',
      grabpay: 'GrabPay',
      grabpay_page: {
        top_up_via: 'GrabPay 充值 '
      },
      modal_page: {
        bank_transfer: '银行转账',
        fee: '费用',
        grabpay: 'GrabPay',
        netslink: 'NETSLink',
        no_fee: '无手续费',
        ok: '好',
        payment_method: '付款方式',
        paynow: 'PayNow',
        please_make_sure: '请确保您的账户中有足够的余额或选择其他付款方式。',
        review_and_confirm: '查看并确认',
        top_up_amount: '充值金额',
        top_up_failed: '充值失败！',
        top_up_successful: '充值成功！',
        total: '总计',
        your_balance: '您的余额将在几分钟内更新'
      },
      nets: 'NETSLink',
      netslink_page: {
        top_up_via: 'NetsLink 充值'
      },
      paynow: 'PayNow',
      paynow_old_page: {
        add_your: '添加您的 PayNow 号码',
        instruction_1: '登录您的银行应用程序，然后选择 PayNow',
        instruction_2: '选择<strong>立即向 VPA 付款</strong>。',
        instruction_3: '输入您的完整 VPA，如下所示：',
        instruction_3_1: '+6591118888#TREEDOTS',
        instruction_3_2: '<strong>复制</strong>',
        instruction_4: '请确保<strong>收款人的昵称</strong>正确无误。',
        instruction_5: '输入您要充值的金额',
        instruction_6: '您的充值金额应在 30 秒内显示在此应用程序上。',
        top_up_via: '通过 PayNow 充值'
      },
      paynow_page: {
        account_name: '账户名称',
        account_name_placeholder: 'TreeDots',
        click_to_download: '点击下载二维码',
        copy: '复制',
        instruction_1_1: '请确保仅使用实际的',
        instruction_1_2: 'PayNow ',
        instruction_1_3: '应用程序进行付款。',
        instruction_2_1: '请确认您正在向 XFERS PTE. LTD. 付款。- 客户账户',
        instruction_title: '转账说明',
        the_qr_code: '二维码仅对该特定交易有效。扫描二维码时，将自动填充金额和参考编号。',
        top_up_via: '通过 PayNow 充值',
        uen: 'UEN（唯一实体编号）',
        uen_placeholder: '4318123452R13'
      }
    },
    total_treedotspay: 'TreeDots Pay 总余额： ',
    transaction_history: '交易历史',
    transfer_bank: '转账至银行',
    transfer_to: '转账至：',
    tree_wallet: 'Tree-Wallet',
    turn_off_refunds_bottom:
      '退款需要 7-10 个工作日才能显示在您的银行中。如需实时接收退款，请将TreeDots Pay 选项设为默认方式。',
    turn_off_refunds_title: '关闭退款至 TreeDots Pay 的选项？',
    turn_off_refunds_top:
      '如果关闭该项功能，您日后取消订单后所收取的全部退款将退回到您结算时使用的银行账户或付款方式。',
    turn_on_refunds:
      '将 TreeDots Pay 设为主要退款方式，让您以后在取消订单后实时接收退款。您可以随时更改此设置。',
    turn_on_refunds_title: '启用将退款打到 TreeDots Pay 的选项？',
    understood: '明白了',
    view_all: '查看全部',
    volume_deal_refund: '批量交易退款',
    withdraw: '提现',
    withdraw_amount: '提现金额：',
    withdraw_appear: '提现金额将显示在您的银行账户中，这可能需要几分钟',
    withdraw_fail_info: '您的提现交易失败。您尝试使用的银行账户似乎存在问题。请重试或选择其他银行。',
    withdrawal: '提现',
    withdrawal_failed: '提现失败',
    your_wallet_is_protected: '您的钱包受到保护。'
  },
  we_are_reviewing_company: '我们正在审核您的公司',
  we_are_sorry: '抱歉！',
  we_are_still_process: '我们仍在审核您的公司',
  we_detected_unusual_activity: '我们检测到您的 IP 地址存在异常活动，并已暂时暂停此功能。请重试：',
  we_have_sent_you_message: '我们已向您注册的手机号码发送了一条包含验证码的短信',
  we_received_registration: '我们已收到您的注册信息，正在审核您的公司。',
  we_will_proceed_to_clear_your_cart: '我们将继续清空您的购物车',
  weight: '重量',
  weight_size: '重量、尺寸',
  welcome_again: '再次欢迎！',
  what_is_collection_point: '什么是取货点？',
  what_type_cuisine_company_sell: '贵司销售哪种类型的菜肴？',
  what_type_of_your_business: '您的企业经营哪种类型的菜肴？',
  whatis_next: {
    content:
      '2021 年对我们来说是激动人心的一年！我们正在吸引更多的供应商和扩大我们的服务，力求成为集供应商订单管理、库存管理、第三方物流和财务服务于一体的一站式解决方案。',
    title: '接下来的是：'
  },
  which_state: '您在哪个州/省？',
  why_my_order_is_on_hold: '为什么我的订单处于暂停状态？',
  with_price: '含价格',
  yes: '是',
  yes_confirm: '是的，确认',
  yes_connect_to_existing_company: '是，连接到现有公司',
  yes_replace_as_contact_person: '是，替换为联系人',
  yes_delete: '是的，删除',
  yes_leave_community_chat: '是，离开',
  yes_proceed_order: '是的，下单',
  yes_reject: '是的，拒绝',
  yesterday: '昨天',
  you_have_company: '您的公司正在等待您执行相关操作',
  you_have_no_company: '您名下没有公司。',
  you_have_overdue: '您有 ({totalInvoices}) 张未付发票',
  you_have_overdue_invoices: '您有 ({totalInvoices}) 张发票已到期。',
  you_have_placed_order: '您已提交 {isOrderBooking}',
  you_will_be_notified: '您将会收到有关产品库存更新的通知',
  you_will_not_be_notified: '您不会收到有关产品库存更新的通知',
  you_will_place_this_order: '您将下达此订单，请继续',
  your_company: '贵司',
  your_information_is_protected: '您的信息受到保护。',
  your_order: '您的订单',
  your_order_will_be_cancelled: '您的订单将被取消，您无需支付任何费用',
  your_referral: '您的推荐',
  your_referral_code: '您的推荐码',
  your_referrees: '您的推荐人',
  your_reward: '您的奖励',
  confirm_create_duplicate_customer_title: '确定要创建重复客户吗？',
  captcha_invalid: '验证码无效',
  manage_cards: '管理卡',
  b2c_customer: 'B2C',
  order_create_b2c_permission_not_authorized: '无权为 B2C 客户下单',
  stores: '商店',
  no_connected_tenant_err: '您没连接任何供应商。请联系您的销售或技术支持。',
  spend_more_to_free_delivery: '再花 {orderAmount} 即可享受免费送货',
  delivery_fees: '送货费',
  free: '免费',
  waived: '免除',
  delivery_fee_updated: '送货费已更新',
  before_tax: '税前',
  after_tax: '税后',
  tax_inclusive: '含税',
  tax_exclusive: '不含税',
  connecting_to: '连接到',
  connect_to_company: '使用 6 位数字代码连接到公司',
  input_provided_code_to_connect: '输入提供的 6 位数字代码以连接到公司',
  enter_six_digits_pin_placeholder: '输入 6 位数字代码',
  do_you_accept_connection: '您接受连接吗？',
  you: '您',
  user_connected: '用户已连接',
  user_inactive: '用户未激活',
  no_user_connected: '没有用户连接',
  view_pin: '查看代码',
  copy_to_clipboard: '复制代码到剪贴板',
  invitation_pin: '邀请密码',
  valid_until: '有效期至',
  expired: '已过期',
  send_new_pin: '发送新代码',
  send_new_pin_message: '确定要邀请 {user_name} 加入公司吗？6 位数字代码将通过短信发送到 {phone_number}。',
  onboarding_pin_input_prompt_message:
    '输入提供的 6 位数字代码以连接到现有公司。或跳过以创建一个新公司，该公司将经过审核和批准。',
  six_digits_pin_code_will_be_sent: '6 位数字代码将发送到 {phone_number}',
  add_pic_with_non_existing_user_message: '{user_name} 将需要注册后，才能使用提供的 6 位数字代码连接到公司',
  add_pic_with_existing_user_message: '{user_name} 将使用提供的 6 位数字代码连接到公司',
  pic_pin_input_limit_message: '验证客户连接PIN码失败五次。请在一分钟后重试。',
  please_wait_for: '请等待',
  phone_number_already_used_by_another_pic: '电话号码已被另一联系人使用',
  not_eligible_for_self_pickup: '不符合自取条件',
  dropship_merging_warning: '注意：{countDropshipItems} 件商品无法合并，将作为单独的订单创建。'
};
export default en;
