import gql from 'graphql-tag';

export const getPurchaseB2bOrders = gql`
  query getPurchaseB2bOrders(
    $statusIds: [Int]
    $customerBuyerIds: [Int]
    $tlTaskStatusIds: [Int]
    $startDeliveryDate: String
    $endDeliveryDate: String
    $limit: Int
    $offset: Int
    $blockStatus: [OrderBlockStatus]
    $orderId: Int
    $tenantId: Int
  ) {
    getPurchaseB2bOrders(
      statusIds: $statusIds
      tlTaskStatusIds: $tlTaskStatusIds
      customerBuyerIds: $customerBuyerIds
      startDeliveryDate: $startDeliveryDate
      endDeliveryDate: $endDeliveryDate
      limit: $limit
      offset: $offset
      blockStatus: $blockStatus
      orderId: $orderId
      tenantId: $tenantId
    ) {
      data {
        id
        version
        total_price
        total_tax
        is_paid
        stripe_transaction_id
        delivery_date
        delivery_time
        delivery_status
        updated_at
        created_at
        payment_type
        stripe_card_id
        reason_cancel
        reason_type_cancel
        reason
        description
        supplier_name
        credit_note
        app_name
        tenant_id
        is_booking
        block_status
        can_update_processed_order
        user {
          first_name
          last_name
        }
        CreatedBy {
          first_name
          last_name
        }
        orderStatus {
          name
        }
        orderLogs {
          description
          user {
            user_type_id
          }
        }
        orderItems {
          id
          sku_id
          invoice_id
          is_delivery_fee
          is_discount_item
          customer {
            account_number
            id
            name
            minimum_order
          }
          sku {
            id
            product {
              id
              name
              discoverability
            }
          }
        }
        paymentTransactionItems {
          id
          order_id
          invoice_id
          payment_transaction_id
          amount
          created_at
          paymentTransaction {
            id
            checkout_session_id
            payment_intent_id
            amount
            payment_link
            payment_type_id
            status
            expires_at
            created_at
            created_by
          }
        }
        display_total_price
        display_total_tax
        display_price
      }
    }
  }
`;

export const getOrderItemDetailB2b = gql`
  query getOrderItemDetailB2b($id: Int, $orderId: Int) {
    getOrderItemDetailB2b(id: $id, orderId: $orderId) {
      id
      invoice_id
      order_id
      tenant_name
      tenant_id
      sku_id
      user_id
      customer_buyer_id
      order_item_status_id
      order_item_status
      customer_seller_id
      amount_qty
      total_qty
      origin_unit_price
      sale_unit_price
      total_price
      tax
      catch_weight
      is_catch_weight
      is_order_by_weight
      active
      cancelled
      sku_name
      product
      oom
      image
      user_name
      mobile
      stripe_card_id
      delivery_status
      delivery_address
      dispute_status
      dispute_reason
      dispute_remedy
      customer_name
      alias_name
      minimum_order
      description
    }
  }
`;
export const getCustomersOrders = gql`
  query getCustomersOrders($searchQueries: String, $isIncludeTotalCount: Boolean, $limit: Int, $offset: Int) {
    getCustomersOrders(
      searchQueries: $searchQueries
      isIncludeTotalCount: $isIncludeTotalCount
      limit: $limit
      offset: $offset
    ) {
      totalCount
      customers {
        id
        account_number
        name
      }
    }
  }
`;

export const orderItemDisputes = gql`
  mutation Mutation($orderItemDisputesPayload: OrderItemDisputesInput!) {
    orderItemDisputes(payload: $orderItemDisputesPayload) {
      id
      order_id
      invoice_id
      sku_id
      product
      oom
      image
      user_id
      customer_buyer_id
      order_item_status_id
      catch_weight
      alias_name
      customer_seller_id
      amount_qty
      origin_unit_price
      sale_unit_price
      total_qty
      total_price
      tax
      active
      minimum_order
      hub
      user_name
      mobile
      stripe_card_id
      tenant_id
      tenant_name
      delivery_status
      cancelled
      dispute_status
      dispute_reason
      dispute_remedy
      delivery_address
    }
  }
`;

export const getInvoiceList = gql`
  query getInvoiceList($id: Int!) {
    getInvoiceList(id: $id) {
      invoice_id
      order_id
      invoice_number
      currency_code
      currency_symbol
    }
  }
`;

export const sendInvoiceToPeppolByOrderId = gql`
  mutation sendInvoiceToPeppolByOrderId(
    $orderId: Int!
    $tenantLegalEntityId: String
    $customerPeppolId: String
    $customerPeppolScheme: String
    $sendOnly: Boolean
  ) {
    sendInvoiceToPeppolByOrderId(
      orderId: $orderId
      tenantLegalEntityId: $tenantLegalEntityId
      customerPeppolId: $customerPeppolId
      customerPeppolScheme: $customerPeppolScheme
      sendOnly: $sendOnly
    )
  }
`;

export const getCustomerPeppol = gql`
  query getCustomerPeppol($id: Int!) {
    getCustomerPeppol(id: $id) {
      id
      peppol_id
      peppol_scheme_id
      peppol_scheme_code
      peppol_scheme
    }
  }
`;

export const getTenantIntegrationStorecove = gql`
  query getTenantIntegrationStorecove($tenantId: Int!) {
    getTenantIntegrationStorecove(tenantId: $tenantId) {
      id
      legal_entity_id
      peppol_id
      peppol_scheme_id
      send_only
      tenant_id
      created_at
      created_by
      updated_at
      updated_by
      active
    }
  }
`;

export const addOrderB2b = gql`
  mutation addOrderB2b(
    $customerId: Int!
    $deliveryAddressId: Int
    $billingAddressId: Int
    $cartItems: [AddOrderItemB2bInput]!
    $isMobile: Boolean
    $isUsingPickupAddress: Boolean
    $appliedCustomerCredit: Float
  ) {
    addOrderB2b(
      customerId: $customerId
      deliveryAddressId: $deliveryAddressId
      billingAddressId: $billingAddressId
      cartItems: $cartItems
      isMobile: $isMobile
      isUsingPickupAddress: $isUsingPickupAddress
      appliedCustomerCredit: $appliedCustomerCredit
    ) {
      id
      token
      redirect_url
      paynowRes {
        id
        token
        url
        payment_intent
        expires_at
        status
        payment_status
        cancel_url
      }
      stripeResponse {
        orderId
        url
        paymentIntent
      }
      blockStatus
      blockMessage
      isBlockedByExceedRemainingCreditLimit
      isBookingOrder
    }
  }
`;

export const updateOrderB2b = gql`
  mutation updateOrderB2b(
    $orderId: Int!
    $orderVersion: Int!
    $deliveryDate: String
    $deliveryTime: String
    $description: String
    $poNumber: String
    $orderItems: [UpdateOrderItemB2bInput]!
  ) {
    updateOrderB2b(
      orderId: $orderId
      orderVersion: $orderVersion
      deliveryDate: $deliveryDate
      deliveryTime: $deliveryTime
      description: $description
      poNumber: $poNumber
      orderItems: $orderItems
    ) {
      id
      token
    }
  }
`;

export const cancelOrderB2b = gql`
  mutation cancelOrderB2b($orderId: Int!, $orderVersion: Int!) {
    cancelOrderB2b(orderId: $orderId, orderVersion: $orderVersion) {
      id
      order_status_id
      active
    }
  }
`;

export const checkAllowDoubleOrder = gql`
  mutation checkAllowDoubleOrder {
    checkAllowDoubleOrder
  }
`;

export const PRICE_ADD = gql`
  mutation PRICE_ADD($skuId: Int!, $buyerId: String, $price: Float, $lastUserId: Int!) {
    priceAdd(skuId: $skuId, buyerId: $buyerId, price: $price, lastUserId: $lastUserId) {
      id
    }
  }
`;

export const getOrderItemAmendments = gql`
  query getOrderItemAmendments($orderItemId: Int!, $isApproved: Int) {
    getOrderItemAmendments(orderItemId: $orderItemId, isApproved: $isApproved) {
      id
      order_item_id
      old_qty
      new_qty
      is_approved
      created_by
      created_at
      active
      user_type
      reason
    }
  }
`;

export const updateOrderItemAmendmentsApprovalById = gql`
  mutation updateOrderItemAmendmentsApprovalById($amendmentId: Int!, $isApproved: Int!) {
    updateOrderItemAmendmentsApprovalById(amendmentId: $amendmentId, isApproved: $isApproved) {
      id
      order_item_id
      old_qty
      new_qty
      is_approved
      created_by
      created_at
      updated_by
      updated_at
      active
    }
  }
`;

export const updateItemReceived = gql`
  mutation updateItemReceived($id: Int!) {
    updateItemReceived(id: $id) {
      success
    }
  }
`;

export const getTotalCountPurchaseB2bOrder = gql`
  query getTotalCountPurchaseB2bOrder(
    $statusIds: [Int]
    $tlTaskStatusIds: [Int]
    $customerBuyerIds: [Int]
    $startDeliveryDate: String
    $endDeliveryDate: String
    $blockStatus: [OrderBlockStatus]
    $tenantId: Int
  ) {
    getTotalCountPurchaseB2bOrder(
      statusIds: $statusIds
      tlTaskStatusIds: $tlTaskStatusIds
      customerBuyerIds: $customerBuyerIds
      startDeliveryDate: $startDeliveryDate
      endDeliveryDate: $endDeliveryDate
      blockStatus: $blockStatus
      tenantId: $tenantId
    )
  }
`;
export const processNetsPaymentPinPad = gql`
  mutation processNetsPaymentPinPad(
    $headers: String
    $requestBody: String
    $newOrderId: Int
    $newOrderItemIds: [Int]
    $netsCardId: Int
    $netsT0205: String
    $amount: Float
    $needCancelOrder: Boolean!
  ) {
    processNetsPaymentPinPad(
      headers: $headers
      requestBody: $requestBody
      newOrderId: $newOrderId
      newOrderItemIds: $newOrderItemIds
      netsCardId: $netsCardId
      netsT0205: $netsT0205
      amount: $amount
      needCancelOrder: $needCancelOrder
    )
  }
`;

export const paymentTransactionDetailsForOrder = gql`
  mutation paymentTransactionDetailsForOrder($orderId: Int!) {
    paymentTransactionDetailsForOrder(orderId: $orderId) {
      id
      checkout_session_id
      payment_intent_id
      amount
      payment_link
      payment_type_id
      status
      expires_at
      created_by
      created_at
      updated_at
      recipient_name
      paymentType {
        id
        name
        is_manual
      }
      paymentTransactionItems {
        id
        order_id
        invoice_id
        payment_transaction_id
        amount
        created_at
        order {
          id
          order_status_id
          delivery_date
        }
      }
      card_info {
        exp_month
        exp_year
        last4
        brand
      }
    }
  }
`;

export const cancelPrepayment = gql`
  mutation cancelPrepayment($stripeCheckoutSessionId: String!) {
    cancelPrepayment(stripeCheckoutSessionId: $stripeCheckoutSessionId)
  }
`;

export const cancelStripePaymentIntent = gql`
  mutation cancelStripePaymentIntent($stripePaymentIntentId: String!) {
    cancelStripePaymentIntent(stripePaymentIntentId: $stripePaymentIntentId)
  }
`;

export const getFluidCustomerInfo = gql`
  query getFluidCustomerInfo($tenantId: Int!, $customerId: Int!) {
    getFluidCustomerInfo(tenantId: $tenantId, customerId: $customerId)
  }
`;
